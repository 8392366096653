<template>
<div class="footer">
  &copy; 2021 - Henry Zilek
</div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer{
  background-color: #3A0092;
  margin: auto;
  padding: 7px;
  color: white;
  font-size: 0.8em;
}
</style>
