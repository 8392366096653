const POINT_SPEC = {
    NO_LOCATION:         0b1,
    NO_PUBLIC: 0b1_0000_0000,
}

const SUB_POINT = {
    TRACK:           0b1_0000,
    CYKLO:          0b10_0000,
    START:      0b1_0000_0000,
    END:       0b10_0000_0000,
    CONTROL:  0b100_0000_0000,
}

export {POINT_SPEC, SUB_POINT};
