<template>
  <div class="wrap" :class="{section : point.section}">
    <div class="date_block" v-if="point.newDate">{{formatDate(this.point.Time)}} </div>
    <div class="point_container block " :class="selectedPoint(point)" @click="setCurPoint(point.ID)">
      <span class="date">{{ localFormatDate }}</span>
      <span class="name">{{ point.Name }}</span>
      <div class="points" v-if="userData">
        <div class="strong">{{ point.ValuePoint }}</div>
        <div class="icon_container">
          <div class="icon_base text_blue" @click="processPoint(point)">
            <i class="fas fa-minus" v-if="point.IsValid === '1'"></i>
            <i class="fas fa-plus" v-else></i></div>
        </div>
      </div>
      <span class="points" v-else>{{ point.prezdivka }}</span>

    </div>
  </div>
</template>

<script>
export default {
  name: "UserPointRow",
  props: {
    point: {},
    userData: Boolean
  },
  methods: {
    setCurPoint(pointID) {
//      console.log("vybrany bod " + JSON.stringify(this.point));
      this.$store.commit('setCurUserPoint', pointID);
    },
    selectedPoint(point) {
//      console.log('Bod: ' + this.point.ID);
      return {
        invalid_point: point.IsValid === '0',
        selected_point: this.$store.state.curUserPoint === point.ID
      };
    },
    processPoint(point){
      console.log("vybrana polozka na zpracovani bodu: "+point.Name);
      this.$emit('processPoint',point);

//      alert(pointID);
    }
  },
  computed: {
    localFormatDate() {
      let dat = this.point.Time;
//      console.log(dat);
//      return dat;
      let date = new Date(dat);
      let dd = date.getDate();
      let mm = date.getMonth() + 1;
      let yyyy = date.getFullYear();
      let time = date.toLocaleTimeString("cs-CZ");
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      return dd + ". " + mm + ". " + yyyy + " " + time;
//      return date.toLocaleDateString("cs-CS");
    },


  },


}
</script>

<style scoped>
.point_container {
  width: 100%;
  margin: auto;
  padding: 3px;
  display: grid;
  grid-template-columns: 30% auto 10%;
  /*  grid-template-rows: 20px;*/
  /*  grid-column-gap: 3px;*/
  grid-template-areas:
        " date name points";
  text-align: left;
  font-size: 0.8em;
  cursor: pointer;
}

.date {
  grid-area: date;
}

.name {
  grid-area: name;
}

.points {
  grid-area: points;
  text-align: center;
}

.block {
  box-shadow: none;
  margin-bottom: 2px;
  border-color: darkgray;
}

.invalid_point {
  background-color: lightgray;
}

.selected_point {
  background-color: #58B15F;
}

.invalid_point.selected_point {
  background-color: coral;
}

.icon_base {
  /*  background-color: blanchedalmond;
    width: 18px;*/
  margin-left: -1px;
  width: auto;
}

.icon_container {
  width: 18px;
  background-color: white;
  border: solid 1px #e9e9e9;
  border-radius: 5px;

}

.wrap{
  padding: 3px;
}

.section{
  background-color: #d1e2fb;
}

.date_block{
  font-size: 0.8em;
text-align: left;
  font-weight: bold;
}

</style>
