<template>
  <Div>Tady bude info o tracku
    <button class="smallButton blue" v-on:click="getMapPoints()">Test Track</button>
    <div></div>
    <button class="smallButton red" v-on:click="postTrackPoly()">Odeslat</button>

  </Div>
</template>

<script>
import gPost from "@/components/ajaxtest/gPost";

export default {
  data: function () {
    return {
      polyline: []
    }
  },
  name: "ContentTrack",
  methods: {
    getMapPoints() {
      const baseURL = "https://maps.googleapis.com/maps/api/directions/json?";
      const dir = "origin=50.461647,13.419017&destination=50.461647,13.419017&waypoints=50.461718,13.419587|50.461121,13.420955|50.45968987493165,13.421094805133903|50.45924249461691,13.417570382774587|50.46122323031548,13.41469505494416&mode=walking&key=AIzaSyDmkZUKH4f70iMrfTQFi8n3gN1aS28RwXI";
      gPost(baseURL + dir, "", (result) => {
        console.log(result);
        this.processPoly(result.routes[0]);
      }, (err) => {
        console.log(err);
      });
    },

    processPoly(...js) {
// var object = JSON.parse(js);
      console.log("tady zobrazim co budu zpracovavat " + js);
      // console.log("tady zobrazim co budu zpracovavat " + object.length);
      let polyline = [];
      for (let i = 0; i < js.length; i++) {
        let legs = js[i].legs;
        for (let j = 0; j < legs.length; j++) {
          let steps = legs[j].steps;
          console.log("Tady josu Polyline " + steps);
          for (let st = 0; st < steps.length; st++) {
            polyline.push(steps[st].polyline.points);
          }
        }
        console.log(legs);
        console.log(polyline);
        this.polyline = polyline;
      }

    },

    postTrackPoly() {
      // let json = JSON.stringify(this.polyline);
      let test2 =  this.polyline.reduce(((posList, item) => posList.concat(this.decode(item))), []);

      let param = {
        userID: this.$store.state.curID,
        userToken: this.$store.state.userToken,
        trackID: "ZTEASYMOST",
        polilynes: test2,
        // polilynes: this.polyline,
        task: "setTrack",
        poli2: test2
      };
      // let test = [];
      //     this.polyline.forEach((item)=> test.push(this.decode(item)));

      // console.log("tady je test " + test);
      console.log("tady je test2 " + test2);

      gPost(this.$store.getters.getUrl, param, ({data, msg}) => {
            let track = JSON.parse(data);
            console.log(track);
            this.$store.commit('setNewMsg', {msg: msg});
          },
          ({msg}) => {
            this.$store.commit('setNewMsg', {msg: msg, msgType: 'alert'});
          });
    },

    decode(encoded) {
      // source: http://doublespringlabs.blogspot.com.br/2012/11/decoding-polylines-from-google-maps.html
      // array that holds the points
      var points = [];
      var index = 0, len = encoded.length;
      var lat = 0, lng = 0;
      while (index < len) {
        var b, shift = 0, result = 0;
        do {

          b = encoded.charAt(index++).charCodeAt(0) - 63;//finds ascii                                                                                    //and substract it by 63
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);


        var dlat = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
        lat += dlat;
        shift = 0;
        result = 0;
        do {
          b = encoded.charAt(index++).charCodeAt(0) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        var dlng = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
        lng += dlng;

        points.push({lat: (lat / 1E5), lng: (lng / 1E5)})

      }
      console.log("Tady budou dekodovane body");
      console.log(points);
      return points;
    }


  }
}

</script>

<style scoped>

</style>