<template>
  <div class="mapa">
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map) && Boolean(this.infowindow)">
      <slot
          :google="google"
          :map="map"
          :infowindow="infowindow"
      />
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';

export default {
  name: "GoogleMapLoader",

  props:{
    mapConfig: Object,
    apiKey: String
  },

  data(){
    return{
      google: null,
      map: null,
      infowindow: null,
    }
  },

  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    this.initializeMap()
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(
          mapContainer, this.mapConfig
      )
      const inWind = new this.google.maps.InfoWindow;
      this.infowindow = inWind;
      console.log("Po inicializaci InfoWindow ");
    }
  }
}
</script>

<style scoped>
.mapa{
  height: 100%;
}
.google-map {
  width: 100%;
  height: 100%;
}
</style>
