<template>
  <div class="points_list">
    <div class="search">
      Hledej:
      <input v-model="whatSearch" type="text">
      <span class="icon_base doAction" v-on:click="clearSearch"><i class="fas fa-times"></i></span>
    </div>
    <div v-if="!$store.state.dataReady[myTask]" class="loader-alert">
      <div class="alert">Načítám data ze serveru. Je jich fakt hodně</div>
      <div class="loader"></div>
    </div>

    <div v-else class="point_content scroll">
      <div v-for="point in pointList" v-bind:key="point.ID">
        <!--        <hr v-if="newDate(point.Time)"/>-->
        <UserPointRow v-bind:point="point" v-bind:user-data="userData" @processPoint="changePoint"/>
      </div>
      <!--      <RecycleScroller :items="pointList" key-field="ID" item-size="20" v-slot="{item}" emit-update>
              <UserPointRow v-bind:point="item" v-bind:user-data="userData" @processPoint="changePoint" />
            </RecycleScroller>
      &lt;!&ndash;      <UserPointRowVirtual :point="pointList"/>&ndash;&gt;-->
    </div>
    <ModalDialog v-if="showModal" :msg="msg" :title="title" btn-cancel="Zpět"
                 btn-o-k="Změnit" show-cancel
                 @closeCancel="selectCancel" @closeOK="selectOK"/>
  </div>
</template>

<script>
//TODO Upravit načítání dlouhých listboxů. Zatím to nejde

import UserPointRow from "@/components/userpoints/UserPointRow";
import ModalDialog from "@/components/modal/ModalDialog";
// import UserPointRowVirtual from "@/components/userpoints/UserPointRowVirtual";

export default {
  name: "UserPointsList",
  // components: {UserPointRowVirtual, ModalDialog, UserPointRow},
  components: {ModalDialog, UserPointRow},
  data() {
    return {
      whatSearch: '',
      showModal: false,
      title: '',
      msg: '',
      updatePoint: {},
      myTask: 'ListPoints',
      showMaxPoints: this.maxPoints,
      prevDate: '',
    }
  },
  props: {
    userID: {
      type: String,
      default: 'ALL'
    },
    minDate: {
      type: String,
      default: ''
    },
    userData: {
      type: Boolean,
      default: true
    },
    maxPoints: {
      type: Number,
      default: 300
    }

  },
  computed: {
    pointList() { //TODO zrychlit to
      console.log("jsem v points listu")
      let minDate = this.minDate === '' ? this.$store.state.curSeasonStart : this.minDate;
      let search = this.whatSearch.length > 0;
      let whatSearch = this.whatSearch.toLowerCase();
      let prevDate = '';
      let section = false;
      const response = [...this.$store.state.listPoints.filter(point => {
        return ((this.userID === 'ALL' ? true : point.ID_User === this.userID) && (point.Time > minDate)) && (search ? point.Name.toLowerCase().includes(whatSearch) : true);
      }).slice(0, this.showMaxPoints)];

      response.forEach((item) => {
        item.newDate = prevDate !== item.Date;
        // item.newDate = prevDate && (prevDate !== item.Date);
        section = item.newDate ? !section : section;
        item.section = section;
        item.prevDate = prevDate;
        prevDate = item.Date;
      });

      return response;
    },
  },
  methods: {

    newDate(curDate) {
      const date = new Date(curDate);
      if (this.prevDate !== date.getDate()) {
        this.prevDate = date.getDate();
        return true;
      } else {
        return false;
      }
    },

    clearSearch() {
      this.whatSearch = '';
    },

    clear() {
      this.whatSearch = '';
    },

    changePoint(point) {
      console.log("jsem v parent zpracovani. Prave resim bod: " + point.Name);
      this.title = "Provést změnu platnosti bodu?";
      this.msg = "Opravdu změnit platnost bodu " + point.Name + "?";
      this.updatePoint = point;
      this.showModal = true;
//      alert(point);
    },

    selectCancel() {
      this.showModal = false;
    },

    selectOK() {
      this.showModal = false;
      this.$store.dispatch('setPointValidity', this.updatePoint);
    }


  }
}
</script>

<style scoped>

.search {
  height: 30px;
}

.points_list {
  height: 100%;
}

.point_content {
  height: calc(100% - 30px);

}

.loader {
  min-height: 60px;
  align-content: center;
  text-align: center;
  margin-top: 30px;
  background: url("../../assets/loader.gif") no-repeat center;
}

.scroll {
  overflow-x: hidden;
}

.nextDate {
  background-color: whitesmoke;
}
</style>
