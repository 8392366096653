<template>
  <div class="point_content">
    <div class="detail block">
      <PointStat/>
    </div>

    <div class="point_filter block">

    </div>

    <div class="points_list block">
      {{ this.$store.getters.getTodayPointCount }}
      <br>
      {{ this.$store.getters.getLastWeekPointCount }}
    </div>

    <div class="points_map block">
      <UserPointsList :max-points="1000" :user-data="false"/>
    </div>

  </div>
</template>

<script>
import UserPointsList from "@/components/userpoints/UserPointsList";
import PointStat from "@/components/points/PointStat";

export default {
  name: "ContentNewUserPoint",
  components: {PointStat, UserPointsList}
}
</script>

<style scoped>
.point_content {
  width: 100%;
  /*  background-color: #c2f7fd;*/
  margin: auto;
  padding: 3px;

  display: grid;
  max-width: 1260px;
  grid-template-columns: 25% auto 25% 25%;
  grid-template-rows: 200px 120px 120px 120px 200px 120px;
  /*  grid-column-gap: 3px;*/
  grid-template-areas:
        " detail detail detail filter"
        " detail detail detail pointslist"
        " map map map pointslist"
        " map map map pointslist"
        " map map map pointslist"
        " map map map pointslist";

}

.detail {
  grid-area: detail;
}

.points_list {
  grid-area: pointslist;
}

.points_map {
  grid-area: map;
}

.point_filter {
  grid-area: filter;
}

</style>
