<template>
  <div class="points_list ">
    <!--  <div class="search">
        Hledej:
        <input type="text" v-model="whatSearch" >
        <span class="icon_base doAction" v-on:click="clearSearch"><i
            class="fas fa-times"></i></span>
      </div>-->
    <div class="point_content scroll">
      <PointRow v-for="point in pointList" :key="point.id" :show-add="canAdd" v-bind:point="point"></PointRow>
    </div>
  </div>
</template>

<script>
import PointRow from "@/components/points/PointRow";
import {mapGetters} from "vuex";

export default {
  name: "PointsList",
  components: {PointRow},
  props: {
    canAdd: Boolean
  },
  data() {
    return {
      whatSearch: '',
    }
  },
  methods: {
    clearSearch() {
      this.whatSearch = '';
    },

    setGlobalPointSearch() {
      this.$store.commit('setSearchInPoint', this.whatSearch);
    },

  },
  computed: {
    pointList() { //TODO zrychlit to

      // let search = this.whatSearch.length > 0;
      // let whatSearch = this.whatSearch.toLowerCase();
//      this.setGlobalPointSearch();
      return this.getSelectedMarkers;
      /*
            return this.$store.state.allPoints.filter(point => {
              return  (search ? point.Name.toLowerCase().includes(whatSearch): true);
            })
      */
    },
    ...mapGetters({
      getSelectedMarkers: 'getMarkers'
    })
  },

  beforeDestroy() {
    this.$store.commit('clearSearchInPoint');
  },


}
</script>

<style scoped>
.points_list {
  height: 100%;
}

.search {
  height: 30px;
}

.point_content {
  /*height: calc(100% - 30px);*/
  height: 100%;
}
</style>
