<template>
  <div>
    <div :class="{inactive: !isActive }" class="title">{{ selectedPoint.Name }} {{ point.new.PointSpec }} <span
        class="btn">
       <button v-if="localEdit" class="smallButton blue" @click="localEdit = !localEdit">Editace</button>
    <button v-else class="smallButton green" @click="localEdit = !localEdit">Konec Editace</button>
    </span></div>
    <hr>
    <div class="text_left margin_left"><span> Point ID:</span> <span>{{ selectedPoint.PointCode }}</span></div>

    <div class="edit_content">
      <div class="col-left">
        <InputBox :changed="point.hasChanged.Name"
                  :local-edit="localEdit"
                  :value="point.new.Name"
                  class="inputDetail"
                  input-type="text"
                  label="Název:"
                  prop="Name"
                  @editData="editData"/>

        <InputBox :changed="point.hasChanged.Address"
                  :local-edit="localEdit"
                  :value="point.new.Address"
                  class="inputDetail"
                  input-type="text"
                  label="Adresa:"
                  prop="Address"
                  @editData="editData"/>

        <InputBox :changed="point.hasChanged.Lat"
                  :local-edit="localEdit"
                  :value="point.new.Lat"
                  class="inputDetail"
                  input-type="text"
                  label="Lat:"
                  prop="Lat"
                  @editData="editData"/>

        <InputBox :changed="point.hasChanged.Lng"
                  :local-edit="localEdit"
                  :value="point.new.Lng"
                  class="inputDetail"
                  input-type="text"
                  label="Lng:"
                  prop="Lng"
                  @editData="editData"/>
      </div>
      <div class="col-right">
        <InputBox :changed="point.hasChanged.Value"
                  :local-edit="localEdit"
                  :value="point.new.Value"
                  class="inputDetail"
                  input-type="number"
                  label="Hodnota:"
                  prop="Value"
                  @editData="editData"/>

        <InputBox :changed="point.hasChanged.Parametr"
                  :check-value="pointParam"
                  :local-edit="localEdit"
                  class="inputDetail"
                  label="Parametry:"
                  input-type="check"
                  prop="Parametr"
                  @editData="editData"/>

        <InputBox :changed="point.hasChanged.Track"
                  :check-value="cykloCheck"
                  :local-edit="localEdit"
                  class="inputDetail"
                  input-type="check"
                  label="Trasy:"
                  prop="Track"
                  @editData="editData"/>

        <InputBox :changed="point.hasChanged.PointType"
                  :check-value="pointType"
                  :local-edit="localEdit"
                  :value="selectedType"
                  class="inputDetail"
                  input-type="radio"
                  label="Typ bodu:"
                  prop="PointType"
                  @editData="editData"/>
      </div>
    </div>

    <div v-if="edited" class="block dialog movable">
      <div class="alert">Uložit rozpracované změny?</div>
      <button class="smallButton red" v-on:click="cancelAllChange">Zrušit</button>
      <button class="smallButton green" v-on:click="confirmAllChange">Uložit</button>

    </div>
  </div>
</template>

<script>
import {POINT_SPEC, SUB_POINT} from "@/constants/pointSetting";
import InputBox from "@/components/editcomponents/InputBox";

export default {
  name: "PointDetail",
  components: {InputBox},
  props: {
    showEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      point: {
        original: {
          Name: '',
          ID: '',
          PointCode: '',
          Value: '',
          Lat: '',
          Lng: '',
          Desc: '',
          Address: '',
          Days: '',
          HoursFrom: '',
          HoursTo: '',
          IsValid: '',
          PointType: '',
          PointSpec: '',
          Cluster: '',
          ShowInCluster: '',
          InRegion: '',
          Images: '',
        },
        new: {
          Name: '',
          ID: '',
          PointCode: '',
          Value: '',
          Lat: '',
          Lng: '',
          Desc: '',
          Address: '',
          Days: '',
          HoursFrom: '',
          HoursTo: '',
          IsValid: '',
          PointType: '',
          PointSpec: '',
          Cluster: '',
          ShowInCluster: '',
          InRegion: '',
          Images: '',
        },
        hasChanged: {
          Name: false,
          ID: false,
          PointCode: false,
          Value: false,
          Lat: false,
          Lng: false,
          Desc: false,
          Address: false,
          Days: false,
          HoursFrom: false,
          HoursTo: false,
          IsValid: false,
          PointType: false,
          PointSpec: false,
          Cluster: false,
          ShowInCluster: false,
          InRegion: false,
          Images: false,
          Parametr: false,
          Coordinates: false,
          Track: false,

        }
      },
      edited: false,
      localEdit: true,
    }
  },
  created() {
    this.$store.commit('setMarkOnMapID', parseInt(this.$store.state.allPoints[0].ID));
  },

  methods: {
    setOrigValue(point) {
      Object.keys(this.point.hasChanged).forEach((key) => this.point.hasChanged[key] = false);
      this.point.original = JSON.parse(JSON.stringify(point));
      this.point.new = JSON.parse(JSON.stringify(point));

    },

    setEdited() {
      this.edited = !Object.keys(this.point.hasChanged).every((key) => !this.point.hasChanged[key]);
    },

    cancelEdit(what) {
      switch (what) {
        case 'Coordinates':
          this.point.new.Lat = this.point.original.Lat;
          this.point.new.Lng = this.point.original.Lng;
          this.point.hasChanged.Lat = this.point.original.Lat !== this.point.new.Lat;
          this.point.hasChanged.Lng = this.point.original.Lng !== this.point.new.Lng;
          this.point.hasChanged.Coordinates = this.point.hasChanged.Lat || this.point.hasChanged.Lng;
          break;
        case 'Parametr':
          this.point.new.PointSpec = this.point.original.PointSpec;
          this.point.new.PointType = this.point.original.PointType;
          this.point.new.IsValid = this.point.original.IsValid;
          this.point.hasChanged.PointSpec = this.point.original.PointSpec !== this.point.new.PointSpec;
          this.point.hasChanged.PointType = this.point.original.PointType !== this.point.new.PointType;
          this.point.hasChanged.IsValid = this.point.original.IsValid !== this.point.new.IsValid;
          this.point.hasChanged.Parametr = this.point.hasChanged.PointSpec || this.point.hasChanged.PointType || this.point.hasChanged.IsValid;
          break;
        case 'Track':
          this.point.new.PointType = this.point.original.PointType;
          this.point.hasChanged.PointType = this.point.original.PointType !== this.point.new.PointType;
          this.point.hasChanged.Track = this.point.hasChanged.PointType;
          break;
        default:
          this.point.new[what] = this.point.original[what];
          this.point.hasChanged[what] = this.point.original[what] !== this.point.new[what];
          break;

      }
      this.setEdited();
    },

    confirmEdit(what) {

      switch (what) {
        case 'Coordinates':
          this.point.hasChanged.Lat = this.point.original.Lat !== this.point.new.Lat;
          this.point.hasChanged.Lng = this.point.original.Lng !== this.point.new.Lng;
          this.point.hasChanged.Coordinates = this.point.hasChanged.Lat || this.point.hasChanged.Lng;
          break;
        case 'Parametr':
          this.point.hasChanged.PointSpec = this.point.original.PointSpec !== this.point.new.PointSpec;
          this.point.hasChanged.PointType = this.point.original.PointType !== this.point.new.PointType;
          this.point.hasChanged.IsValid = this.point.original.IsValid !== this.point.new.IsValid;
          this.point.hasChanged.Parametr = this.point.hasChanged.PointSpec || this.point.hasChanged.PointType || this.point.hasChanged.IsValid;
          break;
        case 'Track':
          this.point.hasChanged.PointType = this.point.original.PointType !== this.point.new.PointType;
          this.point.hasChanged.Track = this.point.hasChanged.PointType;
          break;
        default:
          this.point.hasChanged[what] = this.point.original[what] !== this.point.new[what];
          break;

      }
      this.setEdited();
    },

    cancelAllChange() {
      console.log("jsem v cancelAllChange");
      const pointDetail = this.$store.state.allPoints.find((point) => {
        return parseInt(point.ID) === this.$store.state.showMarkOnMapID;
      });
      this.setOrigValue(pointDetail);
      this.setEdited();
    },

    confirmAllChange() {
      console.log("Potvrzeni zmen");
      this.localEdit = true;
      this.$store.dispatch('updatePoint', this.point.new)
      const pointDetail = this.$store.state.allPoints.find((point) => {
        return parseInt(point.ID) === this.$store.state.showMarkOnMapID;

      });
      this.setOrigValue(pointDetail);
      this.setEdited();
    },

    editData(resp) {
      console.log(`response from data ${resp.hasChanged} ${resp.newValue} ${resp.prop}`)
      const self = this;
      if (resp.hasChanged) {
        switch (resp.prop) {
          case 'Track':
            resp.newValue.forEach(item => {
              switch (item.Name) {
                case 'cyklo':
                  self.point.new.PointType = setStatus(self.point.new.PointType, SUB_POINT.CYKLO, item.Value);
                  break;
                case 'start':
                  self.point.new.PointType = setStatus(self.point.new.PointType, SUB_POINT.START, item.Value);
                  break;
                case 'end':
                  self.point.new.PointType = setStatus(self.point.new.PointType, SUB_POINT.END, item.Value);
                  break;
                case 'control':
                  self.point.new.PointType = setStatus(self.point.new.PointType, SUB_POINT.CONTROL, item.Value);
                  break;
              }
            })
            break;
          case 'Parametr':
            resp.newValue.forEach(item => {
              switch (item.Name) {
                case 'valid':
                  self.point.new.IsValid = item.Value ? '1' : '0';
                  break;
                case 'location':
                  self.point.new.PointSpec = setStatus(self.point.new.PointSpec, POINT_SPEC.NO_LOCATION, item.Value);
                  break;
                case 'public':
                  self.point.new.PointSpec = setStatus(self.point.new.PointSpec, POINT_SPEC.NO_PUBLIC, item.Value);
                  break;
              }
            })
            break;
            case 'PointType':
              var curType = parseInt(self.point.new.PointType);
              curType &= ~15;
              self.point.new.PointType = curType | resp.newValue;
              break;
          default:
            this.point.new[resp.prop] = resp.newValue;
            break;
        }
        this.confirmEdit(resp.prop);
      } else {
        this.cancelEdit(resp.prop);
      }
    },

  },
  computed: {
      cykloCheck() {
      return [{Name: 'cyklo', Text: 'Cyklotrasa', Value: getStatus(this.point.new.PointType, SUB_POINT.CYKLO)},
        {Name: 'start', Text: 'Start', Value: getStatus(this.point.new.PointType, SUB_POINT.START)},
        {Name: 'end', Text: 'Cíl', Value: getStatus(this.point.new.PointType, SUB_POINT.END)},
        {Name: 'control', Text: 'Kontrola', Value: getStatus(this.point.new.PointType, SUB_POINT.CONTROL)}];
    },

    pointType(){
      return [{Name: 'sport', Text: 'Sport', Value: 1},
        {Name: 'cult', Text: 'Kultura', Value: 2},
        {Name: 'env', Text: 'Environment', Value: 3}];
    },

    selectedType(){
      return parseInt(this.point.new.PointType) & 15;
    },

    pointParam() {

      return [{Name: 'valid', Text: 'Aktivní', Value: this.point.new.IsValid === '1'},
        {Name: 'location', Text: 'Bez lokace', Value: getStatus(this.point.new.PointSpec, POINT_SPEC.NO_LOCATION)},
        {Name: 'public', Text: 'Neveřejný', Value: getStatus(this.point.new.PointSpec, POINT_SPEC.NO_PUBLIC)}];
    },

    selectedPoint() {
      console.log("tady si vybiram bod");
      const pointDetail = this.$store.state.allPoints.find((point) => {
        return parseInt(point.ID) === this.$store.state.showMarkOnMapID;
      })

      if (pointDetail != null) {
        this.setOrigValue(pointDetail);
      }
      return pointDetail;
    },

    isActive() {
      return (this.selectedPoint != null) ? this.selectedPoint.IsValid === '1' : false;
    },

  }
}

function getStatus(where, what) {
  return !!(where & what);
}

function setStatus(where, what, value) {
  if (value) {
    where |= what;
  } else {
    where &= ~what;
  }
  return where;
}
</script>

<style scoped>

.edit_content {
  display: grid;
  grid-template-columns: 49% 10px auto;
  grid-template-areas: "left none right";
  font-size: 0.8em;
  text-align: left;
  margin: 0 10px;
}

.col-left {
  grid-area: left;
}

.col-right {
  grid-area: right;
}

.col-div {
  grid-area: none;
}

.title {
  font-weight: bold;
  font-size: 1.4em;
}

.inactive {
  background-color: #cfcfcf;
}

.inputDetail {
  width: 100%;
  margin-bottom: 5px;
}

span.btn {
  float: right;
}

.movable{
  cursor: move;
}
</style>
