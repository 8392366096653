<template>
  <div class="header_status">
    <button class="submit red smallButton" v-if="this.$store.state.logedIn" v-on:click="logOut">Odhlasit</button>

    <div v-if="this.$store.state.logedIn">Přihlášený uživatel: {{ this.$store.state.curUser }}</div>
    <div class="topMargin" v-else>Bohuzel nejsi prihlasen</div>

    <Timer v-if="this.$store.state.logedIn"/>


    <ModalDialog v-if="showLogOut" show-cancel btn-o-k="Odhlásit" btn-cancel="Zrušit" title="Odhlášení"
                 msg="Opravdu se chcete odhlásit?" @closeOK="logOutOK" @closeCancel="logOutCancel"/>
  </div>
</template>

<script>
import Timer from "@/components/timer/Timer";
import ModalDialog from "@/components/modal/ModalDialog";

export default {
  name: "Status",
  data() {
    return {
      showLogOut: false,
    }
  },
  components: {ModalDialog, Timer},
  methods: {
    logOut() {
      this.showLogOut = true;
    },

    logOutOK() {
      this.userLogOut();
    },

    logOutCancel() {
      this.showLogOut = false;
    }


  },

  computed:{
  }
}
</script>

<style scoped>
.topMargin {
  margin-top: 15px;
}
</style>
