<template>
  <div v-if="this.$store.state.curUserPoint" class="user_point_detail ">
    <div class="detail_row">
      <div class="label">Název</div>
      <div class="text">{{ getSelPoint.Name }} <span class="text right">{{ getMethod }} {{ getSource }}</span></div>
    </div>
    <div class="detail_row">
      <div class="label">Adresa</div>
      <div class="text">{{ getSelPoint.Address }}</div>
    </div>
    <div class="detail_row">
      <div class="label">Kód</div>
      <div class="text">{{ getSelPoint.ID_Point }}</div>
    </div>
    <div class="detail_row">
      <div class="label">Čas</div>
      <div class="text">{{ formatDate(getSelPoint.Time) }} {{ getTime(getSelPoint.Time) }}</div>
    </div>
    <div class="detail_row">
      <div class="label">Hodnota</div>
<!--      <div class="text">{{ getSelPoint.ValuePoint }}</div>>-->
      <div>
      <input type="number" v-model="getSelPoint.ValuePoint" >
      </div>
    </div>
    <div class="detail_row">
      <div class="label">Platnost</div>
      <div class="text">{{ getSelPoint.IsValid === "1" ? 'Platný bod' : 'NEPLATNÝ BOD' }}</div>
    </div>
    <div class="detail_row">
      <div class="label">Poloha</div>
      <div class="text"><span class="label tiny">Lat:</span> <span class="text"> {{ getSelPoint.LatP }}</span>
        <span class="label tiny">Lng:</span> <span class="text"> {{ getSelPoint.LngP }}</span></div>
    </div>
    <div class="detail_row">
      <div class="label">Načteno</div>
      <div class="text"><span class="label tiny">Lat:</span> <span class="text"> {{ getSelPoint.Lat }}</span>
        <span class="label tiny">Lng:</span> <span class="text"> {{ getSelPoint.Lng }}</span></div>
    </div>
    <div class="detail_row">
      <div class="label">Odchylka</div>
      <div class="text"><span
          class="text">{{ locDistance(getSelPoint.Lat, getSelPoint.Lng, getSelPoint.LatP, getSelPoint.LngP) }} m</span><span
          class="label">Přesnost</span><span class="text">{{ getSelPoint.accurancy }}</span></div>
    </div>

    <v-date-picker class="inline-block h-full" v-model="date">
      <template v-slot="{ inputValue, togglePopover }">
        <div class="flex items-center">
          <button
              class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
              @click="togglePopover({ placement: 'auto-start' })"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                class="w-4 h-4 fill-current"
            >
              <path
                  d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
              ></path>
            </svg>
          </button>
          <input
              :value="inputValue"
              class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
              readonly
          />
          <div class="">*-++  +
            <button class="smallButton blue" @click="updatePoint">Uložit</button>
          </div>
        </div>
      </template>
    </v-date-picker>

  </div>
</template>

<script>
export default {
  name: "UserPointDetail",
  data() {
    return {
      date: new Date(),
      // getSelPoint: null
    };
  },

  methods: {
    updatePoint(){
  console.log("Update point");
  // this.getCurPoint = this.getSelPoint;
  // this.$store.dispatch('setPointValidity', this.getSelPoint);
  this.$store.dispatch('updateUserPointData', this.getSelPoint);
    },

    updateCurSelPoint(selPoint){
      this.getSelPoint = selPoint;
    }
  },

  computed: {
    getSelPoint() {
      const selPoint = this.$store.getters.getCurUserPoint;
      // this.updateCurSelPoint(selPoint);
      return selPoint;
    },

    getMethod() {
      return this.getSelPoint.PointSource;
    },

    getSource() {
      return this.getSelPoint.phoneID === '1' ? 'Android' : 'Apple'
    },


  },


  // beforeMount() {
  //   this.getSelPoint = {...this.getCurPoint};
  //
  // }
}


</script>

<style scoped>

.detail_row {
  grid-template-columns: 70px auto;
  grid-template-areas: " detail_label detail_text";
  display: grid;
  text-align: left;
  font-size: 0.8em;
}

.label {
  grid-area: detail_label;
}

.text {
  grid-area: detail_text;
}

span.label {
  width: 70px;
  display: inline-block;
}

span.label.tiny {
  width: 30px;
}

span.text {
  width: 100px;
  display: inline-block;
}

span.text.right {
  text-align: right;
  float: right;
}

</style>
