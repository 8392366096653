<template>
  <div class="inputBox">
    <span :class="{changed : getChange}" class="label">{{ label }}</span>

    <span v-if="(edit || !localEdit)" class="edit">
      <input v-if="(inputType === 'text' || inputType === 'number')" v-model="editValue" :type="inputType"
             v-on:focusout="shouldConfirm">

      <span v-if="(inputType === 'check')">
        <span v-for="(check, index) in getCheck" :key="index" class="check">
        <input v-model="check.Value" :checked="check.Value" type="checkbox" @change="shouldConfirm"/> {{ check.Text }}
        </span>
      </span>

      <span v-if="(inputType === 'radio')">
        <span v-for="(check, index) in getCheck" :key="index" class="check hz_checkbox">
        <input v-model="editValue" :value="check.Value" name="radioItem" type="radio"/>
          <label :for="check.Value">{{ check.Text }}</label>
        </span>
      </span>

    </span>
    <span v-else class="edit">
      <span v-if="(inputType === 'text' || inputType === 'number')">
           {{ editValue }}
      </span>

      <span v-if="(inputType === 'check')">
        <span v-for="(check, index) in getCheck" :key="index" :class="{checked : check.Value, unchecked : !check.Value}"
              class="check ">
        {{ check.Text }}
        </span>
      </span>

      <span v-if="(inputType === 'radio')">
        {{ getRadio }}
        <!--        <span v-for="(check, index) in getRadio" :key="index" class="radio ">
                {{ check.Text }}
                </span>-->
      </span>
    </span>

    <span v-if="localEdit" class="control">
    <span v-if="!edit">
        <span class="icon_base text_blue" v-on:click="edit = true">
          <i class="fas fa-pencil-alt"></i>
        </span>
    </span>
    <span v-else>
        <span class="icon_base text_red" v-on:click="cancelEdit">
          <i class="fas fa-times"></i>
        </span>
        <span class="icon_base text_green" v-on:click="confirmEdit">
          <i class="fas fa-check"></i>
        </span>
    </span>
    </span>

  </div>
</template>

<script>
export default {
  name: "InputBox",
  props: {
    label: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'text',
    },
    localEdit: {
      type: Boolean,
      default: true,
    },
    checkValue: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    prop: {
      type: String,
      required: true,
    },
    changed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      localValue: '',
      hasChanged: false,
      localCheck: Array
    }
  },

  methods: {
    confirmEdit() {
      this.edit = false;
      this.localValue = this.localValue || this.value;

      this.hasChanged = this.localValue !== this.value;
      const self = this;
      if (this.inputType === 'check') {
        this.hasChanged = !!this.checkValue.filter(ch => self.localCheck.some(lc => lc.Name === ch.Name && lc.Value !== ch.Value)).length;
        console.log('doslo ke zmenen: ' + this.hasChanged);
        this.$emit('editData', {prop: this.prop, hasChanged: this.hasChanged, newValue: this.localCheck});
      } else {
        this.$emit('editData', {prop: this.prop, hasChanged: this.hasChanged, newValue: this.localValue});
      }
      /*      if (this.inputType === 'radio') {
              this.hasChanged = !!this.checkValue.filter(ch => self.localCheck.some(lc => lc.Name === ch.Name && lc.Value !== ch.Value)).length;
              console.log('doslo ke zmenen radia: ' + this.hasChanged);
              this.$emit('editData', {prop: this.prop, hasChanged: this.hasChanged, newValue: this.localCheck});
            } else {
              this.$emit('editData', {prop: this.prop, hasChanged: this.hasChanged, newValue: this.localValue});
            }*/
    },

    cancelEdit() {
      this.edit = false;
      if (this.inputType === 'check') {
        this.initCheck();
      } else {
        this.localValue = this.value;
      }
      this.hasChanged = false;
      console.log(this.localValue);
      this.$emit('editData', {prop: this.prop, hasChanged: this.hasChanged, newValue: this.localValue});
    },

    shouldConfirm() {
      if (!this.localEdit) {
        this.confirmEdit();
      }
    },

    initCheck() {
      this.localCheck = JSON.parse(JSON.stringify(this.checkValue));
      //     this.localCheck.forEach(item => item.Value === this.radioSel);
      this.localCheck.forEach(item => item.changed = false);
      // this.localCheck = JSON.parse(JSON.stringify(this.checkValue)).forEach(item=> item.checked = false);
//      this.localCheck = [...this.checkValue];

    },

    initRadio() {
      this.localCheck = JSON.parse(JSON.stringify(this.checkValue));
    }

  },

  computed: {

    editValue: {
      get() {
//        this.initCheck();
        return this.value;
      },
      set(newValue) {
        this.localValue = newValue;
      }
    },

    getCheck() {
      this.initCheck();
      return this.localCheck;
    },

    getRadio() {
      //     this.initCheck();
      const actualValue = parseInt(this.value);
      console.log("Vybrana hodnota: " + actualValue);
      this.initRadio();
//      return [...this.localCheck].filter((item) => item.Value === actualValue);
      return [...this.localCheck].find((item) => item.Value === actualValue).Text;
//      return this.localCheck;


    },

    getChange() {
      return this.hasChanged;
    }
  },

  watch: {
    changed(newVal) {
      this.hasChanged = newVal;
    }
  }
}
</script>

<style scoped>
.inputBox {
  display: grid;
  grid-template-columns: 80px auto 30px;
  grid-template-areas: "label edit cont";
}

.inputBox span {
  display: inline-block;
}

.label {
  grid-area: label;
}

.edit {
  grid-area: edit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.control {
  grid-area: cont;
  text-align: right;
}

input {
  border: none;
  border-bottom: solid 1px lightgray;
  border-left: solid 1px lightgray;
  width: 100%;
  /*  background-color: whitesmoke;*/
}

.title {
  font-weight: bold;
  font-size: 1.4em;
}

input[type='checkbox'], input[type='radio'] {
  text-align: left;
  width: auto;
  margin: 0;
}

.inactive {
  background-color: #cfcfcf;
}

.check_value {
  float: left;
  /*width: 90px;*/
  margin-right: 10px;

}

.check {
  float: left;
  margin-right: 10px;
}

.unchecked:before {
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 400;
  content: "\f0c8";
  color: lightgray;
  font-size: 1.2em;
}

.changed {
  color: #00ff00;
  font-weight: bold;
}


.checked:before {
  font-family: "Font Awesome 5 Free", serif;
  font-weight: 400;
  content: "\f14a";

  /*  content: "\f04d";*/
  color: lightgray;
  font-size: 1.2em;
}





</style>
