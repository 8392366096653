<template>
  <div class="point_content">
    <div class="detail block">
<PointDetail :show-edit="true"/>
    </div>

    <div class="point_filter block">
      <FilterPoint />
    </div>

    <div class="points_list block">
      <PointsList />
    </div>

    <div class="points_map block">
      <Mapy :showPoint="this.$store.state.showMarkOnMapID"/>
    </div>

  </div>
</template>

<script>
import PointsList from "@/components/points/PointsList";
import Mapy from "@/components/maps/Mapy";
import PointDetail from "@/components/points/PointDetail";
import FilterPoint from "@/components/points/FilterPoint";

export default {
  name: "ContentPoint",
  components: {FilterPoint, PointDetail, Mapy, PointsList},
}
</script>

<style scoped>
.point_content {
  width: 100%;
  /*  background-color: #c2f7fd;*/
  margin: auto;
  padding: 3px;

  display: grid;
  max-width: 1260px;
  grid-template-columns: 25% auto 25% 25%;
  grid-template-rows: 200px 120px 120px 120px 200px 120px;
  /*  grid-column-gap: 3px;*/
  grid-template-areas:
        " detail detail detail filter"
        " detail detail detail pointslist"
        " map map map pointslist"
        " map map map pointslist"
        " map map map pointslist"
        " map map map pointslist";

}

.detail {
  grid-area: detail;
}

.points_list {
  grid-area: pointslist;
}

.points_map {
  grid-area: map;
}

.point_filter{
  grid-area: filter;
}


</style>
