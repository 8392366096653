<template>
  <div>
    <Header showMenu="false"/>
    <LoginContent/>
  </div>
</template>

<script>
import LoginContent from "@/components/login/LoginContent";
import Header from "@/components/header/Header";

export default {
  name: "Login",
  components: {LoginContent, Header}
}
</script>

<style scoped>

</style>
