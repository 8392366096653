<template>
  <div class="content_container">
    <div class="detail block">
      <UserDetail v-bind:show-user-i-d="this.$store.state.user_selectedID"/>
    </div>

    <div class="activity block">
      <div class="content">
        <UserList/>


      </div>
    </div>
    <div class="labor block">
      <div class="content">
        <UserPointsList :user-i-d="this.$store.state.user_selectedID" />
      </div>
    </div>

    <div class="filter block scroll">
      <FilterPoint />
    </div>

    <div class="helper block">
      <PointsList :can-add="true"/>
      <br>

    </div>
    <div class="task block scroll">
      <div class="content ">
<!--        <UserPointsList :min-date="priorMonth" :user-data="false"/>-->
        <Mapy :show-user-i-d="this.$store.state.user_selectedID"/>
        <UserPointDetail />
      </div>
    </div>

  </div>
</template>

<script>
import UserList from "@/components/users/UserList";
import UserDetail from "@/components/users/UserDetail";
import UserPointsList from "@/components/userpoints/UserPointsList";
import PointsList from "@/components/points/PointsList";
import FilterPoint from "@/components/points/FilterPoint";
import UserPointDetail from "@/components/userpoints/UserPointDetail";
import Mapy from "@/components/maps/Mapy";


export default {
  name: "Content",
  components: {Mapy, UserPointDetail, FilterPoint, PointsList, UserPointsList, UserDetail, UserList},
  data(){
    return{
      whatSearch: '',
      newValue: '',
    }
  },
  created() {
    //TODO Zavolat nacteni dat
//    this.$data.initData();
//    this.$store.dispatch("processData");
  },
  methods:{
  },
  computed:{
    priorMonth(){
      var d = new Date();
      d.setMonth(d.getMonth() - 1);
      return  this.formatDateDB(d.toLocaleDateString());
    }
  }

}
</script>

<style scoped>
.content_container {
  width: 100%;
/*  background-color: #c2f7fd;*/
  margin: auto;
  padding: 3px;

  display: grid;
  max-width: 1260px;
  grid-template-columns: 25% auto 25% 25%;
  grid-template-rows: 120px 200px 120px 120px 200px 120px;
  /*  grid-column-gap: 3px;*/
  grid-template-areas:
        " detail activity activity labor"
        " detail activity activity labor"
        " detail activity activity labor"
        " filter task task labor"
        " helper task task labor"
        " helper task task labor";

}

.detail {
  grid-area: detail;
}

.activity {
  grid-area: activity;
}

.labor {
  grid-area: labor;
}

.helper {
  grid-area: helper;
}

.task {
  grid-area: task;
}

.filter{
  grid-area: filter;
}

</style>
