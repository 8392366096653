<template>
  <div class="user_list_content">
    <div class="search">
      <span>
      Hledej:
      <input type="text" v-model="whatSearch">
      <span class="icon_base " v-on:click="clearSearch"><i class="fas fa-times"></i></span>
      </span>
      <span class="county_sel">
            <select name="zip" id="zip" v-model="county">
      <option  value="all">Všechny okresy</option>
      <option v-for="(item, index) in this.$store.state.CountyList" :key="index" :value="item">{{ item }}</option>
    </select>
      </span>
      <span class="user_count">{{lookUser.length}}</span>
    </div>

    <!--<div v-for="(item, index) in this.$store.state.CountyList" :key="index">{{item}} </div>-->

    <div class="loader-alert" v-if="!$store.state.dataReady[myTask]">
      <div class="alert">Načítám data ze serveru</div>
      <div class="loader"></div>
    </div>

    <div class="user_list" v-else>
      <div class="sort_header"><span class="nick" v-on:click="setSort('nick')"> Prezdivka<span
          :class="showArrow('nick')"> </span> </span><span class="name" v-on:click="setSort('name')"> Jmeno <span
          :class="showArrow('name')"></span></span> <span class="point" v-on:click="setSort('points')"> Body<span
          :class="showArrow('points')"> </span></span><span class="date" v-on:click="setSort('date')"> Datum<span
          :class="showArrow('date')"> </span></span></div>
      <div class="user_content scroll" id="user_list">
        <UserRow v-bind:user="data" v-for="data in lookUser" v-bind:key="data.userID"/>
      </div>
    </div>
    <!--
        <div class="hide">Schovej to {{jentak()}}</div>
    -->

  </div>
</template>

<script>
import UserRow from "@/components/users/UserRow";

export default {
  name: "UserList",
  components: {UserRow},
  data() {
    return {
      filterValue: '',
      whatSearch: '',
      myTask: 'ListUsers',
      newSort: false,
      county: 'all',
    }
  },
  methods: {
    clearSearch() {
      this.whatSearch = '';
    },

    setSort(onWhat) {
      // this.$store.commit('setNewMsg', {msg : "Rovnám data"});

      const curSortOn = this.$store.state.sortOn;
      if (curSortOn === onWhat) {
        this.$store.commit('reverseSortOrder');
      }
      this.$store.commit('setSortOn', onWhat);
      this.newSort = true;
    },

    showArrow(item) {
      let curOrder = this.$store.state.sortOrder;
      let curSortOn = this.$store.state.sortOn;

      return {
        sort_active: curSortOn === item,
        fas: true,
        no_fas: true,
        ['fa-sort-amount-down']: curOrder === -1,
        ['fa-sort-amount-down-alt']: curOrder === 1
      }
    },

    resetSort() {
      this.newSort = false;
    }

  },
  // jentak ukazuje pouziti watch v kombinaci s hlidanou hodnotou pomoci computed property, ktera reaguje na zmenu hodnot ulozenou ve storu
  watch: {
    jentak(oldval, newval) {
      console.log("napis co hledas: " + oldval + " " + newval);
    }
  },

  computed: {

    lookUser: function () {
//      console.log("jsem ve filtru<<")
      const list = this.$store.state.listUsers;
      const search = this.whatSearch.toLowerCase();
      const countyZip = this.$store.state.psc.filter((item) =>item.okres === this.county).map((item)=> item.psc);
      console.log(countyZip);
      const res = !search.length ? list : list.filter(user => user.prezdivka.toLowerCase().includes(search) || user.jmeno.toLowerCase().includes(search) || user.prijmeni.toLowerCase().includes(search));

      const result = this.county === 'all' ? res : res.filter((item) => countyZip.includes(item.psc));

      result.sort((a, b) => {
        let resp;
        switch (this.$store.state.sortOn) {
          case "nick":
            resp = (a.prezdivka.localeCompare(b.prezdivka)) * this.$store.state.sortOrder;
            break;
          case "name":
            resp = (a.prijmeni.localeCompare(b.prijmeni)) * this.$store.state.sortOrder;
            break;
          case "points":
            resp = (parseInt(a.body) - parseInt(b.body)) * this.$store.state.sortOrder;
            break;
          case "date":
            resp = (parseInt(a.dateTS) - parseInt(b.dateTS)) * this.$store.state.sortOrder;
            break;

        }
        return resp;
      })

      if (this.$store.state.user_selectedID === '' || this.newSort) {
        this.$store.commit('setUserSelected', result[0].userID);
        document.getElementById('user_list').scroll({top: 0});
        this.resetSort();
      }
      return result;
    },


  },
  filters: {}
}
</script>

<style scoped>

.search {
  height: 30px;
}

.county_sel, .user_count{
margin-left: 20px;
}

.user_list, .user_list_content {
  height: 100%;
}

.user_content {
  height: calc(100% - 60px);
}

.loader {
  min-height: 60px;
  align-content: center;
  text-align: center;
  margin-top: 30px;
  background: url("../../assets/loader.gif") no-repeat center;
}

.hide {
  display: none;
}


.sort_header {
  width: 100%;
  margin: auto;
  padding: 3px;
  display: grid;
  grid-template-columns: 30% 30% 10% auto;
  /*  grid-template-rows: 20px;*/
  /*  grid-column-gap: 3px;*/
  grid-template-areas:
        " nick name point other";
  text-align: left;
  font-size: 0.8em;
  border-bottom: solid 2px black;
  margin-bottom: 3px;
}


.nick {
  grid-area: nick;
  padding-left: 5px;
}

.name {
  grid-area: name;
}

.point {
  grid-area: point;
  text-align: right;
}

.date {
  grid-area: other;
  text-align: right;
  padding-right: 20px;
  font-family: Consolas;
}

.sort_header span {
  cursor: pointer;
}


.no_fas {
  display: none;
}

.sort_active {
  display: inline-block;
  padding-left: 5px;
}

/*
.sort_header span{
  cursor: pointer;
  transition: filter 0.3s ease 0.3s;
}
*/

.sort_header span:active {
  /*filter: drop-shadow(0 0 0 steelblue);*/
  /*filter: invert(100%) ;*/
  /*filter: blur(1px);*/
  /*  transition: filter 0s;*/
  transform: translate(3px, 3px);
  font-weight: bold;
  /*background-color: #16abf0;*/
}

</style>
