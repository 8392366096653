import axios from "axios";
// import Vue from 'vue';

 // const baseUrl = "http://hz-care.port/make.php";
// const baseUrl = "http://hz-care.onlinesmart.cz/make.php";
// const baseUrl = this.getUrl();



export default async function gPost( baseUrl, param, suc, fail) {
    return axios.post(baseUrl, param, {
        validateStatus: function () {
            return true;
        },
        headers:{
            'Content-Type': 'application/json',
        }
    }).then(response => {
            const resultError = response.data.ERROR;
            if (resultError) {
                fail(response.data);
            } else {
                suc(response.data);
            }
        }
    ).catch(response => {
            response.msg = "Nelze komunikovat se serverem. Chyba spojení."
            fail(response);

        }
    )
}
