<template>
  <div class="info_blok block">
    <div>Informační zpráva</div>
    <hr/>
<!--    <div class="alert" v-if="this.$store.state.lastMsg.length">{{ this.$store.state.lastMsg }}</div>-->
    <InfoBlockMsg v-for="(msg, index) in $store.state.listMsg" :key="index" v-bind:msg="msg.msg" v-bind:msg-type="msg.msgType"></InfoBlockMsg>
  </div>
</template>

<script> //TODO Odstranit puvodni volani pro zobrazeni zprav a vsechny navazujicic funkce
import InfoBlockMsg from "@/components/infoblok/InfoBlockMsg";
export default {
  name: "InfoBlock",
  components: {InfoBlockMsg},
  data() {
    return {
      initLenght: 10,
      curTimer: 0,
      myTimer : 0,
    }
  },
  methods: {
    processTime() {
      if (this.$store.state.updateTimer) {
        this.curTimer = this.initLenght;
        this.$store.commit('setTimerUpdated');
      } else {
        this.curTimer--;
      }
      if (this.curTimer === 0) {
        clearInterval(this.myTimer);
        // this.$store.commit('clearMsg');
      }
    }
  },

  created() {
    this.curTimer = this.initLenght;
    this.myTimer = setInterval(this.processTime, 1000);
  }
}
</script>

<style scoped>
.info_blok {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 300px;
  font-size: small;
}
</style>
