<template>
  <div class="login_content_container">
    <div class="title block">
    </div>
    <div class="image block">
      <MostList/>
<!--      <Mapy />-->
    </div>

    <div class="input block round">
      <form class="login" @submit.prevent.once="login">
        <div class="center strong">Vítejte zpět! Ahoj</div>

        <div class="floating-placeholder">
          <input id="email" required v-model="email" type="text"/>
          <label for="email">Uživatelské jméno / Email</label>
        </div>
        <div class="floating-placeholder">
          <input id="pswd" required v-model="password" type="password"/>
          <label for="pswd">Heslo</label>
        </div>
        <hr/>

        <div class="floating-button center">
          <button type="submit" class="button submit blue " >Přihlásit</button>
        </div>

      </form>
      <div v-on:click="globalHelper">Click me</div>
    </div>

  </div>
</template>

<script>
// import Mapy from "@/components/maps/Mapy";
import MostList from "@/components/users/MostList";
export default {
  name: "LoginContent",
  components: {MostList},
  // components: {Mapy},
  data() {
    return {
      email: "",
      password: "",

    }
  },
  methods: {
    login: function () {
      console.log(process.env.VUE_APP_TITLE);
      let param = {
        user: this.email,
        pswd: this.password,
        task: "login"
      };

      this.processLogin(param);
    }
  },

}
</script>

<style scoped>
.login_content_container {
  width: 100%;
  /*background-color: #c2f7fd;*/
  margin: auto;
  padding: 3px;

  display: grid;
  max-width: 1260px;
  grid-template-columns: 33% auto  33%;
  grid-template-rows: 60px 400px;
  /*  grid-column-gap: 3px;*/
  grid-template-areas:
        " title title title"
        " image image input";

}

.title {
  grid-area: title;
}

.image {
  grid-area: image;
}

.input {
  grid-area: input;
}
</style>
