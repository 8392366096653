<template>
<div :class="msgType" >
  {{msg}}
</div>
</template>

<script>
export default {
  name: "InfoBlockMsg",
  props:{
    msg: String,
    msgType:{
      type: String,
      default: 'info'
    }
  },
  data() {
    return {
      initLenght: 10,
      curTimer: 0,
      myTimer : 0,
    }
  },
  methods: {
    processTime() {
        this.curTimer--;

      if (this.curTimer === 0) {
        clearInterval(this.myTimer);
        this.$store.commit('removeMsg');
      }
    }
  },

  created() {
    console.log(`Created msg: ${this.msg}`);
    console.log("Created msg type: " + this.msgType);
    this.curTimer = this.initLenght;
    this.myTimer = setInterval(this.processTime, 1000);
  }
}
</script>

<style scoped>

</style>
