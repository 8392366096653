<template>
  <div class="map_container">

    <div class="map_content">
      <GoogleMapLoader api-key="AIzaSyBSOAeIeop-ZGLWrZYB7fXZt2z33QA0fgM" :map-config="mapConfig">
        <template slot-scope="{ google, map, infowindow }">
          <span>
          <GoogleMapMarker
              v-for="marker in getMarkers"
              :key="marker.id"
              :marker="marker"
              :google="google"
              :map="map"
              :infowindow="infowindow"
              :showPoint="whichPoint"
          />
          </span>
        </template>
      </GoogleMapLoader>
    </div>
  </div>
</template>

<script>
import GoogleMapLoader from "./GoogleMapLoader";
import GoogleMapMarker from "./GoogleMapMarker";
import {mapSettings} from "@/constants/mapSettings";
import {mapGetters, mapState, mapMutations} from 'vuex';  //Mapovani funkce getters z vuexu pro snadnejsi psani

export default {
  props: {
    showPoint: {
      type: Number,
      required: false,
      default: 0
    },
    showUserID:{
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
  },

  data() {
    return {
      markers: [
        {
          id: "0",
          position: {lat: 50.45947, lng: 13.42124},
          type: 1
        }
      ],
/*
      showPointType: {
        sport: true,
        cult: true,
        eco: true,
      },
      showPoints: '123',
*/
      pointMarkers: [],

      whichPoint: 0,

    };
  },

  methods: {
    selectedPoints(pointType) {
//      this.$store.commit('setShowPointType', pointType);
      this.setShowPointType(pointType);
/*
      console.log("vybrane body");
      this.showPointType[pointType] = !this.showPointType[pointType];
      this.showPoints = (this.showPointType.sport ? '1' : '') + (this.showPointType.cult ? '2' : '') + (this.showPointType.eco ? '3' : '');
*/
    },

    whichToShow(newToShow) {
      this.whichPoint = newToShow;
    },
    ...mapMutations(['setShowPointType'])
  },

  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
        zoom: 15
      };
    },

/*    getMarkers() {
      this.whichToShow(this.showPoint);
      const search = this.$store.state.searchInPoint.length > 0;
      const whatSearch = this.$store.state.searchInPoint.toLowerCase();
      return this.$store.state.allPoints.filter(point =>
          (search ? (point.Name.toLowerCase().includes(whatSearch)) : true) & this.showPoints.includes(((parseInt(point.PointType)) & 15).toString())
      ).map((point) => ({
          id: point.ID,
          pointCode: point.PointCode,
          position: {lat: parseFloat(point.Lat), lng: parseFloat(point.Lng)},
          type: point.PointType,
          name: point.Name,
          address: point.Address,
          value: point.Value,
          valid: point.IsValid,
          region: this.getRegion(point.InRegion),
        })
      );
    },*/

    getMarkers() {
      this.whichToShow(this.showPoint);
      console.log( `Chci zobrazi uzivatele: ${this.showUserID}`);

      if (this.showUserID){  // TODO vyřešit zobrazování InfoWindow při zobrazení pouze načtených bodů uživatele
        const d = new Date();
        const today = new Date(d.toDateString()).valueOf(); //.valueOf();
        console.log(`Dnesni datum ${today}`);

        const userPoints = this.$store.state.listPoints.filter(point=> point.ID_User === this.showUserID & point.Date >= today).map(point=> point.ID_Point);
        const p = this.$store.state.allPoints.filter(point => userPoints.includes(point.PointCode) ).map((point) => ({
              id: point.ID,
              pointCode: point.PointCode,
              position: {lat: parseFloat(point.Lat), lng: parseFloat(point.Lng)},
              type: point.PointType & 15,
              name: point.Name,
              address: point.Address,
              value: point.Value,
              valid: point.IsValid,
              region: " ",
            })
        );
 //       this.whichToShow(p[0].id);
       console.log(p);
       return p;
       /*({
          id: point.ID,
          pointCode: point.ID_Point,
          position: {lat: parseFloat(point.LatP), lng: parseFloat(point.LngP)},
          type: point.PointType,
          name: point.Name,
          address: point.Address,
          value: point.ValuePoint,
          valid: point.IsValid,
          region: " ",
        })*/
      }else {
        return this.getSelectedMarkers;
      }
    },
    mapCenter() {
      return this.markers[0].position;
    },

    ...mapGetters({   // mapovani getter z vuexu
      dataReady: 'getAllDataReady',
      getSelectedMarkers: 'getMarkers'
    }),

    ...mapState(['showPointType']),
  }
};
</script>
<style scoped>

.map_container {
  height: 100%;
}

.map_content {
  height: calc(100% - 30px);
}

.local_header {
  height: 30px;
  padding-top: 5px;
}

#map_sport {
  background-image: url("../../../public/img/mapa_sport_small_gray.png");
  background-repeat: no-repeat;
  background-size: 26%;
}

#map_sport.show {
  background-image: url("../../../public/img/mapa_sport_small.png");
  background-repeat: no-repeat;
  background-size: 26%;
}

#map_sport:hover {
  background-image: url("../../../public/img/mapa_sport_small.png");
  background-repeat: no-repeat;
  background-size: 26%;
  cursor: pointer;
  text-decoration: underline;
}

#map_sport.show:hover {
  background-image: url("../../../public/img/mapa_sport_small_gray.png");
  background-repeat: no-repeat;
  background-size: 26%;
  cursor: pointer;
  text-decoration: underline;
}

#map_kult {
  background-image: url("../../../public/img/mapa_kultura_small_gray.png");
  background-repeat: no-repeat;
  background-size: 22%;
}

#map_kult.show {
  background-image: url("../../../public/img/mapa_kultura_small.png");
  background-repeat: no-repeat;
  background-size: 22%;
}

#map_kult:hover {
  background-image: url("../../../public/img/mapa_kultura_small.png");
  background-repeat: no-repeat;
  background-size: 22%;
  cursor: pointer;
  text-decoration: underline;
}

#map_kult.show:hover {
  background-image: url("../../../public/img/mapa_kultura_small_gray.png");
  background-repeat: no-repeat;
  background-size: 22%;
  cursor: pointer;
  text-decoration: underline;
}

#map_eko {
  background-image: url("../../../public/img/mapa_ekologie_small_gray.png");
  background-repeat: no-repeat;
  background-size: 15%;
}

#map_eko.show {
  background-image: url("../../../public/img/mapa_ekologie_small.png");
  background-repeat: no-repeat;
  background-size: 15%;
}

#map_eko:hover {
  background-image: url("../../../public/img/mapa_ekologie_small.png");
  background-repeat: no-repeat;
  background-size: 15%;
  cursor: pointer;
  text-decoration: underline;
}

#map_eko.show:hover {
  background-image: url("../../../public/img/mapa_ekologie_small_gray.png");
  background-repeat: no-repeat;
  background-size: 15%;
  cursor: pointer;
  text-decoration: underline;
}

.legend {
  text-align: right;
  margin-left: 50px;
}

.legend_text {
  font-weight: normal;
  font-size: small;
  margin-left: 30px;
  line-height: 24px;
  height: 20px;
  padding-left: 28px;
  padding-top: 10px;
}

</style>
