<template>
  <div>
    Celkovy prehled
    <button class="blue smallButton" v-on:click="test">Test</button>
    <div>
      <button class="red smallButton" v-on:click="changeStatus">Test or Live</button>
    </div>
    <div>{{ this.$store.getters.getUrl }}</div>
  </div>
</template>

<script>
export default {
  name: "Summary",
  methods: {
    test() {

/*      console.log('a ' + (() => this.x).bind({x: 5}));
      console.log('b ' + (() => this.x).apply({x: 5}));
      console.log('c ' + (() => this.x).bind({x: 5})());
      console.log('a ' + ("" && 0 && 'abcd'));
      console.log('b ' + ("" || 0 || 'abcd'));
      const arr = [1, 2, 3, 4, 5, 6];
      console.log('a ' + arr.reduce((a, b) => a + b, arr));
      console.log('b ' + arr.forEach(x => x += 1));
      console.log('d ' + arr.reduce((a, b) => a + b, 0));
      console.log(3 + '3' + undefined);
      let x = 0;
      // eslint-disable-next-line no-const-assign
      for (let i = 0; i < 10; i++) {
        x += i;
      }
      console.log(x);

      console.log("Tady dělám testy");

      const myArray = [1, 2, 3, 4, 5];
      const newArray = myArray;
      myArray.push(6);
      console.log(newArray);
      console.log(({x: 5, f: () => this.x}).f());

      var score = [12, 7, 14];
      console.log(Math.max(...score));

      const a = undefined;
      const b = null;
      const c = "";
      console.log('a ' + (a === null));
      console.log('b ' + (b === null));
      console.log('c ' + (c === null));

      var xx = 1;
      console.log(xx ? 'abcd' : '1234');

      console.log('a ' + [1, 2, 3, 4].join(','));
      console.log('b ' + [1, 2, 3, 4].slice(','));
      // console.log('c ' + [1,2,3,4].reduce(','));
      // console.log('d ' + [1,2,3,4].split(','));
      console.log(Math.floor(Math.random() * 3 + 1));
      let test = "" && 0;
      console.log("1. " + (test == ''));
      console.log("2. " + (test === ''));

      function ffoo(a) {
        return a > 0
      }

      function ffoo1(a) {
        return typeof a === 'number'
      }

      console.log(ffoo(45));
      console.log((ffoo("sss")));
      console.log(ffoo1(45));
      console.log((ffoo1("sss")));

      for (var i = 0; i < 20; i++) {
        setTimeout(function () {
          console.log(i);
        }, 100 * i);
      }*/
      var obj ={x: 5, fun: function(){this.x}};

      console.log('a ' + obj.fun());
      console.log('b ' + obj.fun.apply(obj));
      console.log('c ' + obj.fun.bind(obj)());
      console.log(1/0);
      const a = [2,3];
      a.unshift(1);
      const b = [2,3];
      b.push(1);
      const c = [2,3];
      c.pop(1);
      // const d = [2,3];
      // d.prepend(1);
console.log('a ' + a);
console.log('b ' + b);
console.log('c ' + c);
// console.log('d ' + d);
      console.log('a ' + ("" && 0 && 'abcd'));
      console.log('b ' + ("" || 0 || 'abcd'));
      new Promise(resolve => setTimeout(resolve, 1000)).then(() =>console.log('ahoj'));
      console.log('a ' + [1].concat(2).concat(3));
      console.log('b ' + [1].concat([2,3]));

      console.log("Tady delam soucty")
      const arr = [1, 2, 3, 4, 5, 6];
      console.log('a ' + arr.reduce((a, b) => a + b, arr));
      console.log('b ' + arr.forEach(x => x += 1));
      console.log('d ' + arr.reduce((a, b) => a + b, 0));

console.log("jak dostat 5")
      console.log('a ' + (() => this.x).bind({x: 5}));
      console.log('b ' + (() => this.x).apply({x: 5}));
      console.log('c ' + (() => this.x).bind({x: 5})());

      console.log("filter");
      console.log("a " + [1, 2, 3, 4, 5].filter((function(value){return value % 2 === 0})));
      console.log("b " + [1, 2, 3, 4, 5].filter(value => value % 2 === 0));


      const a1 = [2,1,5];
      const b1 = a1.sort((x,y) => x - y);
      console.log("a1 " + a1);
      console.log("b1 " + b1);

      console.log([0, 1, 2, 3, 4].filter(Boolean).filter(x=> x % 2 ===1).map(x => x*2));

      console.log(typeof (![]+''));

      const aaa = typeof [];
      console.log(aaa);

      console.log("testuju honzu");
      const name = 'honza';
      console.log("a " +(name.indexOf('honza')> -1));
      console.log("b " +name.includes('honza'));
      console.log("c " +(name.indexOf('honza')> 0));
      console.log("d " +(name.indexOf('honza')>= -1));
      console.log("e " +name.indexOf('honza'));

      setImmediate(()=> console.log("hned"));
      setTimeout(()=>console.log("timed"), 0);
      console.log("neco");

      console.log(navigator.appName);
      console.log(navigator.appCodeName);
      console.log(navigator.appVersion);

      const map = new Map();
      map.set({},{heslo: '1234'});
      // console.log('a ' + map.[{}].heslo);
      // console.log('b ' + map.get({}).heslo);
      // console.log(('c '+ map[0].heslo));
      function ff(foo){return foo}

      console.log('a ' + ff.bind(5)());
      console.log('b ' + ff.bind(null,5).apply());
      console.log('c ' + ff.bind(null, 5)());
      console.log('d ' + ff.apply(5));

      console.log('co to je ' + [...[...'...']].length);



    },
    changeStatus() {
      this.$store.commit('setTest', !this.$store.state.test);
      this.userLogOut();
    }
  }
}
</script>

<style scoped>

</style>
