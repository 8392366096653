<template>
  <div>
    <div class="alert" v-if="showWarning">Brzy budeš odhlášen</div>
    <div class="small"> Zbývající čas {{ nextCount }} &nbsp;<span>
         <button class="smallButton green" v-on:click="resetCounter">Reset</button>
    </span></div>

  </div>
</template>

<script>
export default {
  name: "Timer",
  data() {
    return {
      curTime: 3600,
      defaultTime: 3600,
      lowLimit: 50,
      showWarning : false,
    }
  },
  methods: {
    nextStep: function () {
      this.curTime--;
      if (this.curTime === 0) {
        this.userLogOut();
      }
      if (this.curTime === this.lowLimit){
        this.showWarning = true;
      }
    },

    resetCounter: function () {
      this.curTime = this.defaultTime;
      this.showWarning = false;
    }
  },
  computed: {
    nextCount: function () {
      let min = (Math.trunc(this.curTime / 60)).toString();
      let sec = (this.curTime % 60).toString();
      return min.toString().padStart(2,"0") + ":" + sec.toString().padStart(2,"0");
    }
  },
  created() {
    setInterval(this.nextStep, 1000);
  }
}
</script>

<style scoped>
.small {
  font-size: small;
}

</style>
