<template>
  <div id="app">

    <Main v-if="this.$store.state.logedIn"/>
    <Login v-else/>
    <Footer/>
    <InfoBlock v-if="msgExist"/>
  </div>
</template>

<script>
import Main from "@/components/Main";
import Login from "@/components/Login";
import InfoBlock from "@/components/infoblok/InfoBlock";
import Footer from "@/components/footer/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    InfoBlock,

    Login,
    Main,
  },
  data: function () {
    return {
      main: true,

    };
  },
  methods: {},

  computed: {
    msgExist() {
      let exist = false;
      /* if (this.$store.state.lastMsg != null && this.$store.state.lastMsg.length>0){*/
      if (this.$store.state.listMsg != null && this.$store.state.listMsg.length > 0) {
        exist = true;
      }
      return exist;
    }
  }
}
</script>

<style>


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: auto;
  width: 1260px;
/*  background-color: whitesmoke;*/
}
</style>
