<template>
  <div>
    <div class='nav'>
      <ul>
        <li :class="{selected: selectedItem( 'summary')}">
          <span v-on:click="setMenuItem('summary')">Home</span>
        </li>
        <li>
  <span>
            Calendar
            <i class='fa fa-caret-down'></i>
          </span>
          <ul class='menu'>
            <li>
              <a href='#event_one'>Event #1 vvvvvvvvvvvvvvvvvvvvvv</a>
            </li>
            <li>
              <a href='#event_two'>Event #2</a>
            </li>
            <li>
              <a href='#event_three'>Event #3</a>
            </li>
          </ul>
        </li>
        <li :class="{selected: selectedItem('userList')}">

          <span v-on:click="setMenuItem('userList')">Prehled Uzivatelu</span>
        </li>
        <li>
            <span>
            Soutěžní body
            <i class='fa fa-caret-down'></i>
          </span>
          <ul class='menu'>
            <li :class="{selected: selectedItem('pointList')}">
              <span v-on:click="setMenuItem('pointList')">Prehled LiS bodu</span>
            </li>
            <li :class="{selected: selectedItem('allPointList')}">
              <span v-on:click="setMenuItem('allPointList')">Načtené LiS body</span>
            </li>
            <li :class="{selected: selectedItem('track')}">
              <span v-on:click="setMenuItem('track')">Trasy</span>
            </li>
          </ul>
        </li>
        <li>
          <a href='#blog'>Blog</a>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopMenuRow",
  methods: {
    setMenuItem(item) {
      console.log('V nabidce jsem vybral ' + item);
      this.$store.commit('setMenuSelection', item);
    },
    selectedItem: function (menuItem) {
      return this.$store.state.curMenu === menuItem;
    }
  },
  computed: {}
}
</script>

<style scoped>
body {
  background: #e9e9e9;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span {
  cursor: pointer;
}

.nav {
  display: inline-block;
  background: #FFFFFF;
  border-radius: 3px;
  border-top: 2px solid #410099;
  height: 40px;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  position: relative;
  display: inline-block;
  height: 38px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  float: left;
}

.nav ul li:first-child,
.nav ul li:nth-child(2),
.nav ul li:last-child {
  border-left: 0;
}

.nav ul li a,
.nav ul li span {
  position: relative;
  display: block;
  padding: 0 25px;
  color: #666666;
  font-size: 14px;
  line-height: 38px;
  text-decoration: none;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.nav ul li a:hover,
.nav ul li.selected a:hover,
.nav ul li span:hover,
.nav ul li.selected span:hover {
  background: #f2f2f2;
  color: #666666;
}

.nav ul li a i,
.nav ul li span i {
  margin: 0 0 0 6px;
}

.nav ul li .menu {
  /*
    display: none;
  */
  position: absolute;
  background: #FFFFFF;
  top: 100%;
  width: 180px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
/*
  border-bottom: 3px solid #410099;
  border-radius: 0 0 3px 3px;
*/
  overflow: hidden;

  height: 0;
  opacity: 0;

  transition: all 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}

.nav ul li:hover .menu {
  display: block;
  opacity: 1;
  height: auto;
  /*
    animation: fadeIn 1s;
  */
  /*
    transition: all 0.3s linear 0s;
  */
}

.nav ul li .menu li {
  display: block;
  width: 100%;
  height: 38px;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.nav ul li .menu li:nth-child(2),
.nav ul li .menu li:nth-last-child(2) {
  height: 38px;
}

.nav ul li .menu li a {
  background: #FFFFFF;
  padding: 0 15px 0 30px;
  color: #666666;
  line-height: 38px;
  text-align: left;
}

.nav ul li .menu li a:hover,
.nav ul li .menu li.selected a:hover {
  background: #f2f2f2;
  color: #666666;
}

.info {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}

.info h1 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
}

.info span {
  color: #666666;
  font-size: 12px;
}

.info span a {
  color: #000000;
  text-decoration: none;
}

.info span .fa {
  color: #410099;
}

.info span .spoilers {
  color: #999999;
  margin-top: 5px;
  font-size: 10px;
}

.selected {
  background-color: #410099;
  color: white;
}

.nav ul li.selected a,
.nav ul li.selected span {
  color: white;
}

li span:hover:active {
  transform: translate(3px, 3px);
}

</style>
