<template>
  <div>
    <div class="row row_container" v-on:click.capture="setSelectedUser(user.userID)"
         :class=" selectClass" >
      <div class="nick">
        {{ user.prezdivka }}
      </div>
      <div class="name">
        {{ user.jmeno }} {{ user.prijmeni }}
      </div>
      <div class="point">
        {{ user.body }}
      </div>
      <div class="date">
        {{ curDate }}
      </div>
      <!--
          <ModalWindow v-if="showModal" @closeOK="selectConfirm(user.userID)" title="Pozor" msg="Nejsou uloženy provedené změny. Opravdu chcete pokra4ovat bez jejich uložení?" ></ModalWindow>
      -->
    </div>
    <ModalDialog v-if="showModal" @closeOK="selectConfirm(user.userID)" @closeCancel="selectCancel()" title="Pozor"
                 msg="Nejsou uloženy provedené změny. Opravdu chcete pokražovat bez jejich uložení?" btn-cancel="Zpět"
                 btn-o-k="Pokračovat" show-cancel></ModalDialog>


  </div>
</template>

<script>
//import ModalWindow from "@/components/modal/ModalWindow";
import ModalDialog from "@/components/modal/ModalDialog";

export default {
  name: "UserRow",
  components: {
    ModalDialog,
//    ModalWindow,
  },
  props: {
    user: {},

  },
  data() {
    return {
      count: 0,
      showModal: false,
    }
  },
  created() {
    this.userPoints = this.$store.state.listPoints;
  },
  methods: {
    setSelectedUser: function (userID) {
      //     var current = new Date();
//      console.log("Vyber jmena: " + current + ":" + current.getMilliseconds());
      if (this.$store.state.changeToCommit) {
        this.showModal = true;
      } else {
        this.$store.commit('setUserSelected', userID);
        this.$store.commit('setCurUserPoint', '');
      }
    },

    selectConfirm(userID) {
      this.showModal = false;
      this.$store.commit('setUserSelected', userID);
    },

    selectCancel() {
      console.log("zrusit");
      this.showModal = false;
    },
    poitsCount(userID) {
//      return this.$store.getters.getTotalCount;
//      console.log("Points count");
      let totalCount = 0;
      this.$store.state.listPoints.filter(item => {
        if ((item.ID_User === userID) && (item.IsValid === "1") && (item.Time > this.$store.state.curSeasonStart)) {
          totalCount += parseInt(item.ValuePoint);
        }
      });
      return totalCount;
    }

  },

  computed: {
    curDate() {
      return(this.formatDate(this.user.datum));
    },

    selectClass(){
      return {active : this.user.userID === this.$store.state.user_selectedID,
        notActivated : this.user.activated ==='0',
        notParent : this.user.activated ==='-1',
        notUser : this.user.activated ==='-2'
      }
    },

  }
}
</script>

<style scoped>

.row {
  border-bottom: solid 1px lightgray;
  cursor: pointer;
}

.row:hover {
  background-color: #c8f2ff;
}

.active {
  background-color: #42b983;
}

.notActivated{
  background-color: lightgray;
}

.notUser{
  background-color: beige;
}
.notParent{
  background-color: orange;
}

.row_container {
  width: 100%;
  margin: auto;
  padding: 3px;
  display: grid;
  grid-template-columns: 30% 30% 10% auto;
  /*  grid-template-rows: 20px;*/
  /*  grid-column-gap: 3px;*/
  grid-template-areas:
        " nick name point other";
  text-align: left;
  font-size: 0.8em;


}

.row_container div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nick {
  grid-area: nick;
  padding-left: 5px;
}

.name {
  grid-area: name;
}

.point {
  grid-area: point;
  background-color: blanchedalmond;
  text-align: right;
}

.date {
  grid-area: other;
  text-align: right;
  padding-right: 5px;
  font-family: Consolas;
}

</style>
