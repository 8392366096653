<template>
  <div class="pointStat">
    <div class="statRow">
      <span class="label">Počet načtených bodů dnes</span><span class="statInfo">{{ getPointCount() }} <span
        class="sub"> <span
        class="sublabel">Děti</span><span class="subinfo">{{ getPointCountCat() }}</span></span>
      <span class="sub"> <span
          class="sublabel">Dospělí</span><span class="subinfo">{{ getPointCountCat('NAD18') }}</span></span>
      </span>
    </div>

    <div class="statRow">
      <span class="label">Počet načtených bodů za posledních 7 dní</span><span
        class="statInfo">{{ getPointCount(7) }}
    <span
        class="sub"> <span
        class="sublabel">Děti</span><span class="subinfo">{{ getPointCountCat('DO18',7) }}</span></span>
      <span class="sub"> <span
          class="sublabel">Dospělí</span><span class="subinfo">{{ getPointCountCat('NAD18',7) }}</span></span>

    </span>
    </div>
    <div class="statRow">
      <span class="label">Počet načtených bodů za posledních 30 dní</span><span
        class="statInfo">{{ getPointCount(30) }}
      <span
          class="sub"> <span
          class="sublabel">Děti</span><span class="subinfo">{{ getPointCountCat('DO18',30) }}</span></span>
      <span class="sub"> <span
          class="sublabel">Dospělí</span><span class="subinfo">{{ getPointCountCat('NAD18',30) }}</span></span>


    </span>
    </div>
  </div>

</template>

<script>
export default {
  name: "PointStat",
  methods: {
    getPointCount(lenght = 0) {
      const d = new Date();
      d.setDate(d.getDate() - lenght);
      const today = new Date(d.toDateString()).valueOf(); //.valueOf();
      console.log(`Dnesni datum ${today}`);
      return this.$store.state.listPoints.filter((item) => item.Date >= today & item.IsValid === '1').reduce((count) => count += 1, 0);
    },

    getPointCountCat(category = 'DO18', lenght = 0) {
      console.log("Pointcategory zobrazenia  vypocet.");
      const userList = [...this.$store.state.listUsers.filter((item) => item.user_category.includes(category)).map((user) => user.userID
          /*          {
                  return {userID: user.userID}
                }*/
      )].join();


      const d = new Date();
      d.setDate(d.getDate() - lenght);
      const today = new Date(d.toDateString()).valueOf(); //.valueOf();
      console.log(`Dnesni datum ${today}`);
      return this.$store.state.listPoints.filter((item) => item.Date >= today & item.IsValid === '1' & userList.includes(item.ID_User)).reduce((count) => count += 1, 0);
    },
  }
}
</script>

<style scoped>
.statRow {
  display: grid;
  grid-template-columns: 280px auto;
  grid-template-areas: "label info";
  font-size: 0.8em;
  text-align: left;
}

.sub {
  display: inline-block;
  font-size: 0.8em;
}

.statRow span {
  display: inline-block;
}


.label {
  grid-area: label;

}

.sublabel {
  margin-left: 10px;
  width: 40px;
}

.subinfo {
  width: 30px;
}

.statInfo {
  grid-area: info;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
