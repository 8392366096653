<template>
<div class="user_list_content">
      <UserRow v-bind:user="data" v-for="data in this.$store.state.listUsersOnPoint" v-bind:key="data.userID"/>
</div>
</template>

<script>
import UserRow from "@/components/users/UserRow";
export default {
  name: "MostList",
  components:{UserRow}
}
</script>

<style scoped>

</style>