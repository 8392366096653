import Vue from 'vue'
import App from './App.vue'
import './assets/HZ_general.css';
import store from "./store";
import VueVirtualScroller from 'vue-virtual-scroller'
import gPost from "@/components/ajaxtest/gPost";
//import VCalendar from 'v-calendar';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

// const cors = require('cors');
//
// Vue.use(cors());
Vue.use(VueVirtualScroller);
Vue.component('v-date-picker', DatePicker);

Vue.mixin({
    data: function () {
        return {
            // baseUrl: "http://hz-care.port/make.php",
        }
    },
    methods: {
        globalHelper: function () {
            alert("Hello world")
        },

        locDistance(latA, lngA, latB, lngB) {
            return ((latA === latB) && (lngA === lngB) || (latA === 0)) ? 0 : Math.floor(rad2deg(Math.acos(Math.sin(deg2rad(latA)) * Math.sin(deg2rad(latB)) + Math.cos(deg2rad(latA)) * Math.cos(deg2rad(latB)) * Math.cos(deg2rad(lngA - lngB)))) * 60 * 1.1515 * 1609.344);

        },

        newTestValue(newValue) {
            this.test2.setValue(newValue);
        },

        formatDate(dateValue) {
            let date = new Date(dateValue);
            let dd = date.getDate().toString().padStart(2, "0");
            let mm = (date.getMonth() + 1).toString().padStart(2, "0");
            let yyyy = date.getFullYear();
            return `${dd}.${mm}.${yyyy}`;
        },

        formatDateDB(dateValue) {
            let date = new Date(dateValue);
            let dd = date.getDate().toString().padStart(2, "0");
            let mm = (date.getMonth() + 1).toString().padStart(2, "0");
            let yyyy = date.getFullYear();
            return `${yyyy}-${mm}-${dd}`;

        },

        getType(type) {
            let typeString = {1: 'Sport', 2: 'Kultura', 3: 'Environment'};
            let mainType = type & 7;
            return typeString[mainType];
        },

        getTime(dateValue) {
            const date = new Date(dateValue);
            return date.toLocaleTimeString("cs-CZ");
        },

        getRegion(regID) {
            let regions = this.$store.state.regions;
            let curReg = regions.find((reg) => {
                return reg.regionID === regID;
            });
            if (curReg == null) {
                curReg = {region: "Region nepřiřazen"};
            }
            return curReg.region;
        },

        userLogOut() {
            console.log("jsem v odhlaseni");

            this.$store.commit('clearUser');
            this.$store.commit('setLoged', false);
            this.$store.commit('setNewMsg', {msg: "Uživatel odhlášen"});
            sessionStorage.setItem('loged', 'false');
        },
        processLogin: function (param) {
            this.$store.commit('setNewMsg', {msg: "Odesílám data na server"});

            gPost(this.$store.getters.getUrl, param, ({data}) => {
                    this.$store.commit('setUser', data);
                    this.$store.commit('setLoged', true);
                    this.initDataAll(this.$store).then(()=>this.$store.dispatch('prepareZIP'));
                    this.$store.commit('setMenuSelection', 'summary');
                },
                ({msg}) => {
                    this.$store.commit('setNewMsg', {msg: msg, msgType: 'alert'});
                });

        },

        initData: async function (store) {
            let param = {
                userID: store.state.curID,
                userToken: store.state.userToken
            };

            for (let key in this.$store.state.dataReady) {
                param.task = key;
                await gPost(this.$store.getters.getUrl, param, function (response) {
                        if (response.ERROR === "true") {
                            store.commit('setNewMsg', {msg: response.msg});
                        } else {
                            validResponse(store, key, response);
                        }
                    },
                    function (response) {
                        store.commit('setNewMsg', response);
                    });
            }


        },
        initDataAll: async function (store) {
            let task =[];
            let param = {
                userID: store.state.curID,
                userToken: store.state.userToken
            };

            for (let key in this.$store.state.dataReady) {
                param.task = key;
                task.push(gPost(this.$store.getters.getUrl, param, function (response) {
                        if (response.ERROR === "true") {
                            store.commit('setNewMsg', {msg: response.msg +" "+key});
                        } else {
                            response.msg += " "+key
                            validResponse(store, key, response);
                        }
                    },
                    function (response) {
                        store.commit('setNewMsg', response);
                    })
                );

            }

            await Promise.all(task).then(()=>console.log('Toto je az po nacteni veskerych dat'));


        },

        prevSeson(store){
            let param = {
                userID: store.state.curID,
                userToken: store.state.userToken,
                task: 'ListUsers',
                season: 'prev'
            };

            gPost(this.$store.getters.getUrl, param, function (response) {
                    if (response.ERROR === "true") {
                        store.commit('setNewMsg', {msg: response.msg});
                    } else {
                        validResponse(store, 'ListUsers', response);
                    }
                },
                function (response) {
                    store.commit('setNewMsg', response);
                })

            param.task = 'ListPoints' ;
            gPost(this.$store.getters.getUrl, param, function (response) {
                    if (response.ERROR === "true") {
                        store.commit('setNewMsg', {msg: response.msg});
                    } else {
                        validResponse(store, 'ListPoints', response);
                    }
                },
                function (response) {
                    store.commit('setNewMsg', response);
                })

        },

        readMost: function(store, pointID){
            console.log("IN READ MOST**************************************")
            let param = {
                task: 'ListUsersOnPoint',
                pointId: pointID,
            };
            gPost(this.$store.getters.getUrl, param, function (response) {
                        if (response.ERROR === "true") {
                            store.commit('setNewMsg', {msg: response.msg});
                        } else {
                            validResponse(store, 'ListUsersOnPoint', response);
                        }
                    },
                    function (response) {
                        store.commit('setNewMsg', response);
                    })

        }

    },


})

new Vue({
    el: '#app',
    render: h => h(App),
    store
});


function validResponse(store, task, resp) {
    store.commit(`set${task}`, resp.data);
    store.commit('setNewMsg', {msg: resp.msg});
    store.dispatch('setDbReady', task);
    // store.commit('setReady', task);

    // if (store.getters.getAllDataReady())
    //     store.dispatch('prepareZIP');
}

function deg2rad(degrees) {
    const pi = Math.PI;
    return degrees * (pi / 180);
}

function rad2deg(radians) {
    const pi = Math.PI;
    return radians * 180 / pi;
}
