<template>
  <div class="main_container">
    <Header showMenu="true" />

    <Summary v-if="showMe('summary')"/>

    <ContentMain v-if="showMe('userList')"/>

    <ContentPoint v-if="showMe('pointList')"/>

    <ContentNewUserPoint v-if="showMe('allPointList')" />

    <ContentTrack v-if="showMe('track')"/>

  </div>
</template>

<script>

import Header from "@/components/header/Header";
import ContentMain from "@/components/content/Content";
import Summary from "@/components/summary/Summary";
import ContentPoint from "@/components/points/ContentPoint";
import ContentNewUserPoint from "@/components/points/ContentNewUserPoint";
import ContentTrack from "@/components/track/ContentTrack";

export default {
  name: "Main",
  components: {ContentTrack, ContentNewUserPoint, ContentPoint, Summary, ContentMain, Header},
  methods:{
    showMe: function(item) {
            return this.$store.state.curMenu === item;
    }
  },
  computed: {
    allReady() {
//      return this.$store.state.userReady && this.$store.state.catReady && this.$store.state.pointsReady;
      return true;
    },

  }
}
</script>

<style scoped>
.main_container {
  width: 100%;
/*  background-color: #e8faff;*/
  margin: auto;
}


</style>
