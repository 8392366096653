<template>
  <div class=" block point_row_container" :class="{inactive : point.valid === '0', selected : parseInt(point.id) === this.$store.state.showMarkOnMapID}" @click="showPoint(parseInt(point.id))">
    <div class="region " >{{ this.point.region }}</div>
    <div class="type" :class="this.getType(point.type)">{{ this.getType(point.type) }}</div>
    <div class="title">{{ point.name }}</div>
    <div class="address">{{ point.address }}
      <span v-if="showAdd" class="icon_container">
        <div class="icon_base text_blue" @click="addPoint(point.pointCode)">
          <i class="fas fa-plus"></i></div>
      </span>
    </div>

    <ModalDialog v-if="confirm"
                 @closeOK="selectOK"
                 @closeCancel="selectCancel"
                 :title="'Připsání bodu'"
                 :msg="point.name"
                 btn-cancel="Zrušit"
                 btn-o-k="Přidat"
                 show-cancel/>


<!--    <div class="menu"><span class="icon_base text_blue"><i class="fas fa-pencil-alt"></i></span></div>-->
  </div>
</template>

<script>
import ModalDialog from "@/components/modal/ModalDialog";
export default {
  name: "PointRow",
  components: {ModalDialog},
  props: {
    point: Object,
    showAdd: Boolean,
  },

  data(){
    return{
      confirm: false,
    }
  },


  methods: {

    showPoint(pointID){
      this.selected = true;
      this.$store.commit('setMarkOnMapID', pointID);
    },

    addPoint(pointID){
      console.log("Vybran bod cislo: " + pointID);
      this.confirm = true;
    },

    selectCancel(){
      this.confirm = false;
    },

    selectOK(){
      this.confirm = false;
      this.$store.dispatch('addPointToUser',this.point.pointCode);
    }


  }
}
</script>

<style scoped>
.point_row_container {
  width: 100%;
  margin: auto;
  padding: 3px;
  display: grid;
  grid-template-columns: 60% auto;
  grid-template-rows: auto auto auto auto;
  /*  grid-column-gap: 3px;*/
  grid-template-areas:
        "region type"
  "title title"
  "address address"
  "menu menu";
  text-align: left;
  font-size: 0.8em;
  cursor: pointer;
}

.block {
  box-shadow: none;
  margin-bottom: 2px;
  border-color: darkgray;
}

.region {
  grid-area: region;
  font-size: x-small;
}

.type {
  grid-area: type;
  text-align: center;
  font-weight: bold;
  color: white;
  border-radius: 4px;
}

.title {
  grid-area: title;
  font-weight: bold;
}

.address {
  grid-area: address;
  font-size: x-small;
}

.menu {
  grid-area: menu;

}


.icon_base {
  /*  background-color: blanchedalmond;
    width: 18px;*/
  margin-left: 0;
  margin-top: -1px;
  width: auto;
}

.icon_container {
  width: 18px;
  background-color: white;
  border: solid 1px #e9e9e9;
  border-radius: 5px;
  float: right;

}


</style>
