<template>
  <div>
    <div class="title" :class="{adult: isAdult }">{{ curParty.nick }}</div>
    <hr>
    <table>
      <tr>
        <td class="label">User ID:</td>
        <td class="value">{{ showUserID }}</td>
      </tr>
      <tr>
        <td class="label">Jmeno:</td>
        <td class="value" v-if="!editName"><span :class="{changed : newName}">{{ curParty.fullName }}</span></td>
        <td class="value" v-else><span><input type="text" v-model="curParty.fullName"></span></td>

        <td class="icon" v-if="!editName"><span class="icon_base text_blue" v-on:click="setEdit('name')"><i
            class="fas fa-pencil-alt"></i></span></td>
        <td class="icon" v-else><span class="icon_base text_red" v-on:click="cancelEdit('name')"><i
            class="fas fa-times"></i></span>
          <span class="icon_base text_green" v-on:click="confirmEdit('name')"><i class="fas fa-check"></i></span></td>

      </tr>

      <tr>
        <td class="label">Prezdivka:</td>
        <td class="value" v-if="!editNick"><span :class="{changed : newNick}">{{ curParty.nick }}</span></td>
        <td class="value" v-else><span><input type="text" v-model="curParty.nick"></span></td>

        <td class="icon" v-if="!editNick"><span class="icon_base text_blue" v-on:click="setEdit('nick')"><i
            class="fas fa-pencil-alt"></i></span></td>
        <td class="icon" v-else><span class="icon_base text_red" v-on:click="cancelEdit('nick')"><i
            class="fas fa-times"></i></span>
          <span class="icon_base text_green" v-on:click="confirmEdit('nick')"><i class="fas fa-check"></i></span></td>

      </tr>

      <tr>
        <td class="label">Email:</td>
        <td class="value" v-if="!editEmail"><span :class="{changed : newEmail}">{{ curParty.email }}</span></td>
        <td class="value" v-else><span><input type="text" v-model="curParty.email"></span></td>

        <td class="icon" v-if="!editEmail"><span class="icon_base text_blue" v-on:click="setEdit('email')"><i
            class="fas fa-pencil-alt"></i></span></td>
        <td class="icon" v-else><span class="icon_base text_red" v-on:click="cancelEdit('email')"><i
            class="fas fa-times"></i></span>
          <span class="icon_base text_green" v-on:click="confirmEdit('email')"><i class="fas fa-check"></i></span></td>

      </tr>
      <tr>
        <td class="label">Mobil:</td>
        <td class="value" v-if="!editMobil"><span :class="{changed : newMobil}">{{ curParty.mobil }}</span></td>
        <td class="value" v-else><span><input type="text" v-model="curParty.mobil"></span></td>

        <td class="icon" v-if="!editMobil"><span class="icon_base text_blue" v-on:click="setEdit('mobil')"><i
            class="fas fa-pencil-alt"></i></span></td>
        <td class="icon" v-else><span class="icon_base text_red" v-on:click="cancelEdit('mobil')"><i
            class="fas fa-times"></i></span>
          <span class="icon_base text_green" v-on:click="confirmEdit('mobil')"><i class="fas fa-check"></i></span></td>

      </tr>
      <tr>
        <td class="label">Nar:</td>
        <td class="value" v-if="!editNar"><span :class="{changed : newNar}">{{ this.formatDate(curParty.nar) }}</span>
        </td>
        <td class="value" v-else><span><input type="text" v-model="curParty.nar"></span></td>

        <td class="icon" v-if="!editNar"><span class="icon_base text_blue" v-on:click="setEdit('nar')"><i
            class="fas fa-pencil-alt"></i></span></td>
        <td class="icon" v-else><span class="icon_base text_red" v-on:click="cancelEdit('nar')"><i
            class="fas fa-times"></i></span>
          <span class="icon_base text_green" v-on:click="confirmEdit('nar')"><i class="fas fa-check"></i></span></td>

      </tr>
      <tr>
        <td class="label">PSČ:</td>
        <td class="value">
          <div >{{ curParty.zip.replace(/(\d{3})/g, '$1 ') }} {{getCity.city}}</div>
        </td>
      </tr>
      <tr>
        <td class="label">Okres:</td>
        <td class="value">
          <div >{{getCity.county}}</div>
        </td>
      </tr>
      <tr>
        <td class="label">Kategorie:</td>
        <td class="value">
          <div v-for="cat in curParty.category" :key="cat[0]">{{ cat[1] }}</div>
        </td>
      </tr>

    </table>
    <br>
    <div class="block dialog" v-if="edited">
      <div class="alert">Uložit rozpracované změny?</div>
      <button class="smallButton red" v-on:click="cancelAllChange">Zrušit</button>
      <button class="smallButton green" v-on:click="confirmAllChange">Uložit</button>
    </div>
    <br>
    <br>

    <div>Celkem bodů: {{ poitsCount }}</div>

  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';  //Mapovani funkce getters z vuexu pro snadnejsi psani

export default {
  name: "UserDetail",
  props: {
    showUserID: String,
  },
  data() {
    return {
      editName: false,
      editNick: false,
      editMobil: false,
      editEmail: false,
      editNar: false,
      newName: false,
      newNick: false,
      newMobil: false,
      newEmail: false,
      newNar: false,
      origValue: '',
      origID: '',
      edited: false,
      showModal: false,
    }
  },
  created() {
    this.setOrigValue();
  },

  watch: {
    showUserID: function (origValue, newValue) {
      if (origValue !== newValue) {
        this.edited = false;
        this.$store.commit('setChangeToCommit', false);
        this.setOrigValue();
      }
    }
  },

  methods: {
    setOrigValue() {
//      if (!this.edited || (this.origID !== this.showUserID)) {
      if ((this.origID !== this.showUserID)) {
        this.origValue = JSON.parse(JSON.stringify(this.curParty));
        this.origID = this.showUserID;
        this.cancelAllChange();
      }
    },
    setEdit(what) {
      console.log("ahoj z editace");

      this.setOrigValue();
      switch (what) {
        case "name":
          this.editName = true;
          break;
        case "nick":
          this.editNick = true;
          break;
        case "email":
          this.editEmail = true;
          break;
        case "mobil":
          this.editMobil = true;
          break;
        case "nar":
          this.editNar = true;
          break;

      }
    }
    ,
    cancelEdit(what) {
      switch (what) {
        case "name":
          this.editName = false;
          this.curParty.fullName = this.origValue.fullName;
          break;
        case "nick":
          this.editNick = false;
          this.curParty.nick = this.origValue.nick;
          break;
        case "email":
          this.editEmail = false;
          this.curParty.email = this.origValue.email;
          break;
        case "mobil":
          this.editMobil = false;
          this.curParty.mobil = this.origValue.mobil;
          break;
        case "nar":
          this.editNar = false;
          this.curParty.nar = this.origValue.nar;
          break;
      }
      this.setEdited();
    },
    confirmEdit(what) {
      switch (what) {
        case "name":
          this.editName = false;
          break;
        case "nick":
          this.editNick = false;
          break;
        case "email":
          this.editEmail = false;
          break;
        case "mobil":
          this.editMobil = false;
          break;
        case "nar":
          this.editNar = false;
          break;
      }
      this.setEdited();
    },
    setEdited() {
      let self = this;
      this.edited = !isSame(this.origValue, this.curParty) ||
          (this.editName || this.editNick || this.editEmail || this.editMobil || this.editNar);

      this.$store.commit('setChangeToCommit', this.edited);

      //TODO upravit porovnani hodnot v originalnim objektu s novym objektem

      function isSame(origValue, newValue) {
        console.log("porovnani");
        self.newName = origValue.fullName !== newValue.fullName && !self.editName;
        self.newNick = origValue.nick !== newValue.nick && !self.editNick;
        self.newEmail = origValue.email !== newValue.email && !self.editEmail;
        self.newMobil = origValue.mobil !== newValue.mobil && !self.editMobil;
        self.newNar = origValue.nar !== newValue.nar && !self.editNar;

        return (origValue.fullName === newValue.fullName &&
            origValue.nick === newValue.nick &&
            origValue.email === newValue.email &&
            origValue.mobil === newValue.mobil &&
            origValue.nar === newValue.nar

        )
      }
    },

    cancelAllChange() {
      console.log("jsem v cancelAllChange");
      this.curParty.fullName = this.origValue.fullName;
      this.curParty.nick = this.origValue.nick;
      this.curParty.email = this.origValue.email;
      this.curParty.mobil = this.origValue.mobil;
      this.curParty.nar = this.origValue.nar;
      this.editName = this.editNick = this.editEmail = this.editMobil = this.editNar = false;
      this.newName = this.newNick = this.newEmail = this.newMobil = this.newNar = false;
      this.setEdited();
    },

    confirmAllChange() {
      console.log("Potvrzeni zmen");
      this.$store.dispatch('updateUData', this.curParty);


      this.editName = this.editNick = this.editEmail = this.editMobil = this.editNar = false;
      this.newName = this.newNick = this.newEmail = this.newMobil = this.newNar = false;
      this.origID = '';
      this.setOrigValue();
      this.setEdited();
      //     this.edited = false;

    },


  },
  computed: {
    curParty() {
      const curUser = this.listUsers.find( (cur) => cur.userID === this.user_selectedID);
      if (curUser != null) {
        return {
          nick: curUser.prezdivka,
          fullName: curUser.jmeno + ' ' + curUser.prijmeni,
          email: curUser.email,
          mobil: curUser.mobil,
          nar: curUser.nar,
          category: catList(curUser.user_category, this.listCategory),
          zip: curUser.psc,
        };
      } else {
        return {};
      }
    },

    getCity(){
      let psc = this.curParty.zip;
      let result = this.$store.state.psc.find((item)=> item.psc === psc.replace(/\s+/g, ''));
      if (result){
        return {
          city: result.mesto,
          county: result.okres,
        };
      }else{
        return {city: "Obec nebyla nalezena",
          county: "N/A"};
      }
    },

    isAdult() {
      return this.curParty.category[0][0].includes("NAD18"); // todo upravit zjisteni kategorie
    },

    poitsCount() {
      const pointCount = this.listPoints.filter(item => (item.ID_User === this.showUserID) && (item.IsValid === "1") && (item.Time > this.curSeasonStart))
          .reduce((total, curValue) => total += parseInt(curValue.ValuePoint), 0);
      const userData = {
        userID: this.showUserID,
        pointCount: pointCount
      };
      this.$store.commit('setUserPointsCount', userData);
      return pointCount;
    },

    ...mapGetters({   // mapovani getter z vuexu
      dataReady: 'getAllDataReady'
    }),

    ...mapState(['curSeasonStart', 'user_selectedID', 'listCategory', 'listPoints', 'listUsers']),

  }

}

function catList(category, categoryList) {
  console.log("Jsem v catlistu");
  let cat = category.trim();
  const result = Array();
  do {
    let start = cat.search("{");
    let end = cat.search("}");
    let catID = cat.substring(start + 1, end - start);
    let category = categoryList.find(item => {
      return item.ID_category === catID;
    });
    if (category != null) {
      result.push([category.ID_category, category.name_category]);
    }
    cat = cat.substring(end + 1);
  } while (cat.length > 0);
  return result;
}

</script>

<style scoped>
table {
  font-size: 0.8em;
}

td {
  text-align: left;
  vertical-align: text-top;
}

td.label {
  width: 70px;
}

td.value {
  max-width: 190px;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td.icon {
  min-width: 30px;
  text-align: right;
}

.changed {
  color: #00ff00;
  font-weight: bold;
}

input {
  border: none;
  border-bottom: solid 1px lightgray;
  border-left: solid 1px lightgray;
  /*  background-color: whitesmoke;*/
}

.title {
  font-weight: bold;
  font-size: 1.4em;
}

.adult {
  background-color: khaki;
}
</style>
