<template>
  <div class="header" :class="{test : !this.$store.state.test}">

    <div class="site_logo">
      <a href="/">
        <img src="../../assets/HZ logo.png"/>
      </a>
      <button @click="restoreData">dbRefresh</button>
      <button @click="lastSeason" :class="{active}">Minulá sezóna</button>
<!--      <button @click="readMostData('200801152919')">Most Kratká trasa</button>-->
<!--      <button @click="readMostData('200801152906')">Most Dlouhá trasa</button>-->
<!--      <button @click="readMostData('200801152938')">Most Pěší trasa</button>-->
      <button @click="readMostData('200801152855')">Most Bezpečně dopravou</button>
    </div>
    <div class="header_main_container">

      <TopMenuRow v-if="showMenu === 'true'"/>
      <!--      <TopMenu menuName="Pokus" v-if="showMenu === 'true'"></TopMenu>-->
    </div>
    <div class="header_info_container">
      <Status></Status>
    </div>

  </div>
</template>

<script>

import Status from "@/components/header/Status";
import TopMenuRow from "@/components/menu/TopMenuRow";

export default {
  name: "Header",
  data(){
    return{
      active: false
    }
  },
  components: {TopMenuRow, Status},
  props: {
    showMenu: String
  },
  methods:{
    restoreData(){
      this.initDataAll(this.$store);
      this.$store.commit('setMenuSelection', 'summary');
      this.active = false;
    },

    lastSeason(){
      this.prevSeson(this.$store);
      this.$store.commit('setMenuSelection', 'summary');
      this.active = true;

    },

    readMostData(pointID){
      console.log("STARTING MOST RE")
      this.readMost(this.$store, pointID);
    }
  }

}
</script>

<style scoped>
.header {
  margin: auto;
  width: 100%;

  display: grid;
  grid-template-columns: 20% auto 25% 20%;
  /*  grid-template-rows:  120px 200px 200px 200px 200px 120px;*/
  grid-column-gap: 3px;
  grid-template-areas:
    " logo main main status";
  padding: 3px;;
}

.site_logo {
  grid-area: logo;
  padding: 10px;
}

.site_logo img {
  width: 100%;
  margin: auto;

}

.header_main_container {
  padding-top: 5px;
  /*  background-color: white;*/
  grid-area: main;
}

.header_info_container {
  /*  background-color: #73c1d4;*/
  grid-area: status;
}

.test{
  background-color: orange;
}

button{
  margin-left: 10px;
}
button.active{
  background-color: #409DD7;
}
</style>
