<template>
  <div class="filter_content">
    <div class="search">
      Hledej:
      <input v-model="whatSearch" type="text">
      <span class="icon_base " v-on:click="clearSearch"><i class="fas fa-times"></i></span>
    </div>

    <div class="local_header">
      <span class="legend">
      <span id="map_sport" :class="{show : showPointType.sport}" class="legend_text "
            v-on:click="selectedPoints('sport')"> Sport </span>
      <span id="map_kult" :class="{show : showPointType.cult}" class="legend_text " v-on:click="selectedPoints('cult')"> Kultura </span>
      <span id="map_eko" :class="{show : showPointType.eco}" class="legend_text " v-on:click="selectedPoints('eco')"> Environment </span>
      </span>
    </div>
    <div>Parametry <span class="icon_base" @click="showParam = !showParam">
  <i v-if="showParam" class="fas fa-eye"></i>
  <i v-else class="fas fa-eye-slash"></i>
</span></div>
    <div :class="showPointType" class="check_row hz_checkbox " v-if="showParam">
      <span class="check "><input id="active" v-model="active" :checked="active" type="checkbox"/><label for="active"> Aktivní</label></span>
      <span class="check"><input id="noActive" v-model="noActive" :checked="noActive" type="checkbox"/><label
          for="noActive"> Neaktivní</label></span>
      <span class="check"><input id="location" v-model="location" :checked="location" type="checkbox"/><label
          for="location"> S lokací</label></span>
      <span class="check"><input id="noLocation" v-model="noLocation" :checked="noLocation" type="checkbox"/><label
          for="noLocation"> Bez lokace</label></span>
      <span class="check"><input id="isPublic" v-model="isPublic" :checked="isPublic" type="checkbox"/><label
          for="isPublic"> Veřejný</label></span>
      <span class="check"><input id="noPublic" v-model="noPublic" :checked="noPublic" type="checkbox"/><label
          for="noPublic"> Neveřejný</label></span>
      <span class="check"><input id="cyklo" v-model="cyklo" :checked="cyklo" type="checkbox"/><label for="cyklo"> Cyklotras</label></span>
      <span class="check"><input id="noCyklo" v-model="noCyklo" :checked="noCyklo" type="checkbox"/><label
          for="noCyklo"> Ne Cyklotrasa</label></span>
    </div>

  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: "FilterPoint",
  data() {
    return {
      whatSearch: '',
      active: this.$store.state.active,
      noActive: this.$store.state.noActive,
      location: this.$store.state.location,
      noLocation: this.$store.state.noLocation,
      isPublic: this.$store.state.public,
      noPublic: this.$store.state.noPublic,
      cyklo: this.$store.state.cyklo,
      noCyklo: this.$store.state.noCyklo,
      start: false,
      cil: false,
      control: false,
      showParam: false,
    }
  },
  methods: {
    clearSearch() {
      this.whatSearch = '';
    },

    setGlobalPointSearch() {
      this.$store.commit('setSearchInPoint', this.whatSearch);
    },

    selectedPoints(pointType) {
      this.setShowPointType(pointType);
    },

    setActive() {
      console.log("ukaz active: " + this.active);
      this.$store.commit('setShowActive', this.active);
      console.log("ukaz active: " + this.active);
    },

    ...mapMutations(['setShowPointType']),


  },

  watch: {
    whatSearch: function (newValue) {
      console.log(newValue);
//      this.$store.commit('setSearchInPoint', newValue);
      this.setGlobalPointSearch();
    },

    active: function (newValue) {
      this.$store.commit('setShowActive', newValue);
    },

    noActive: function (newValue) {
      this.$store.commit('setShowNoActive', newValue);
    },

    location: function (newValue) {
      this.$store.commit('setLocation', newValue);
    },

    noLocation: function (newValue) {
      this.$store.commit('setNoLocation', newValue);
    },

    isPublic: function (newValue) {
      this.$store.commit('setPublic', newValue);
    },

    noPublic: function (newValue) {
      this.$store.commit('setNoPublic', newValue);
    },

    cyklo: function (newValue) {
      this.$store.commit('setCyklo', newValue);
    },

    noCyklo: function (newValue) {
      this.$store.commit('setNoCyklo', newValue);
    },


  },

  computed: {
    ...mapState(['showPointType']),
  }

}
</script>

<style scoped>
.filter_content {
  font-size: 0.8em;
}

#map_sport {
  background-image: url("../../../public/img/mapa_sport_small_gray.png");
  background-repeat: no-repeat;
  background-size: 26%;
}

#map_sport.show {
  background-image: url("../../../public/img/mapa_sport_small.png");
  background-repeat: no-repeat;
  background-size: 26%;
}

#map_sport:hover {
  background-image: url("../../../public/img/mapa_sport_small.png");
  background-repeat: no-repeat;
  background-size: 26%;
  cursor: pointer;
  text-decoration: underline;
}

#map_sport.show:hover {
  background-image: url("../../../public/img/mapa_sport_small_gray.png");
  background-repeat: no-repeat;
  background-size: 26%;
  cursor: pointer;
  text-decoration: underline;
}

#map_kult {
  background-image: url("../../../public/img/mapa_kultura_small_gray.png");
  background-repeat: no-repeat;
  background-size: 22%;
}

#map_kult.show {
  background-image: url("../../../public/img/mapa_kultura_small.png");
  background-repeat: no-repeat;
  background-size: 22%;
}

#map_kult:hover {
  background-image: url("../../../public/img/mapa_kultura_small.png");
  background-repeat: no-repeat;
  background-size: 22%;
  cursor: pointer;
  text-decoration: underline;
}

#map_kult.show:hover {
  background-image: url("../../../public/img/mapa_kultura_small_gray.png");
  background-repeat: no-repeat;
  background-size: 22%;
  cursor: pointer;
  text-decoration: underline;
}

#map_eko {
  background-image: url("../../../public/img/mapa_ekologie_small_gray.png");
  background-repeat: no-repeat;
  background-size: 15%;
}

#map_eko.show {
  background-image: url("../../../public/img/mapa_ekologie_small.png");
  background-repeat: no-repeat;
  background-size: 15%;
}

#map_eko:hover {
  background-image: url("../../../public/img/mapa_ekologie_small.png");
  background-repeat: no-repeat;
  background-size: 15%;
  cursor: pointer;
  text-decoration: underline;
}

#map_eko.show:hover {
  background-image: url("../../../public/img/mapa_ekologie_small_gray.png");
  background-repeat: no-repeat;
  background-size: 15%;
  cursor: pointer;
  text-decoration: underline;
}

.legend {
  text-align: right;
}

.legend_text {
  font-weight: normal;
  font-size: small;

  line-height: 24px;
  height: 24px;
  padding-left: 18px;
  padding-top: 10px;
  margin-right: 20px;
  /*  padding-right: 20px;*/
}

.local_header {
  margin-top: 10px;
}

.check {
  margin-right: 10px;

}

.check_row {
  font-size: 0.8em;
  text-align: left;
}


/**
* obsluha check boxu, ktere vypadaji jako tlacitka
*/


div.hz_checkbox span {
  list-style: none;
  padding: 20px;
  padding: unset;
}

div.hz_checkbox span {
  display: inline;
}


div.hz_checkbox span label {
  display: inline-block;
  background-color: rgba(255, 255, 255, .9);
  /*    background-color: #8080FF;*/
  /*    border: 2px solid rgba(139, 139, 139, .3);*/
  border: 1px solid gray;
  /*    border: 2px solid #80FFFF;*/
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
}

div.hz_checkbox span label {
  /*    padding: 8px 12px;*/
  padding: 0px 6px;
  cursor: pointer;
  font-size: x-small;
}

div.hz_checkbox span label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px;
  content: "\f067";
  transition: transform .3s ease-in-out;
}

div.hz_checkbox span input[type="checkbox"]:checked + label::before {
  content: "\f00c";
  /*    content: "\f044";*/
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

div.hz_checkbox span input[type="checkbox"]:checked + label {
  /*    border: 2px solid #1bdbf8;
  background-color: #12bbd4;
  */
  /*    border: 2px solid #000080;*/
  border: 1px solid grey;
  background-color: #3A0092;
  color: #fff;
  transition: all .2s;
}

/*div.hz_checkbox span li input[type="checkbox"] {
display: absolute;
}
*/
div.hz_checkbox span input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

div.hz_checkbox span input[type="checkbox"]:focus + label {
  /*  border: 2px solid #e9a1ff;*/
  /*border: 2px solid #FFFF00;*/
}

div.hz_checkbox span input[type="checkbox"]:hover + label {
  /*  border: 2px solid #e9a1ff;*/
  /*border: 2px solid #FFFF00;*/
  background-color: #C0C0C0;
  color: black;
}


</style>
