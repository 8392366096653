import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";
import gPost from "@/components/ajaxtest/gPost";
import {POINT_SPEC, SUB_POINT} from "@/constants/pointSetting";

Vue.use(Vuex);

//const baseUrlTest = "http://hz-care/make.php";
const baseUrlTest = "http://hz-care.port/make.php";
const baseUrlLife = "http://hz-care.onlinesmart.cz/make.php";
// const baseUrlLife = "make.php";

let localLogedIn = (sessionStorage.loged === "true");
let localUserToken = ((typeof sessionStorage.token === "undefined") ? '' : sessionStorage.token);
// let localUserID = sessionStorage.userid == null ? '' : sessionStorage.userid;
// let localUserNick = sessionStorage.nick == null ? '' : sessionStorage.nick;
let localUserID = sessionStorage.userid || '';
let localUserNick = sessionStorage.nick || '';


export default new Vuex.Store({
    strict: false,               // asi hodne zpomaluje... po prepnuti na false jde obnovovani podstane rychleji
    state: {
        test: false,
        hasError: false,
        curID: localUserID,
        curUser: localUserNick,
        userToken: localUserToken,
        logedIn: localLogedIn,
        curUserPoint: '',
        // updateTimer: false,
        dataReady: {
            Psc: false,
            ListUsers: false,
            ListPoints: false,
            ListCategory: false,
            AllPoints: false,
            Regions: false,
        },
        // dataReady: {userdb: true, pointdb:true},
        allDataReady: false,
        listMsg: [],
        listUsers: [],
        listPoints: [],
        listCategory: [],
        allPoints: [],
        regions: [],
        countyList:[],
        psc:[],
        listUsersOnPoint:[],
        user_serachData: '',
        user_selectedID: '',
        searchInPoint: '',
        curSeasonStart: "2022-09-01",
        changeToCommit: false,
        curMenu: '',
        sortOn: 'nick',
        sortOrder: 1,
        showMarkOnMapID: 0,
        showPointType: {
            sport: true,
            cult: true,
            eco: true,
        },
        showPoints: '1234',
        active: true,
        noActive: false,
        location: true,
        noLocation: true,
        public: true,
        noPublic: true,
        cyklo: true,
        noCyklo: true,
    },

    mutations: {
        setUserPointsCount(cur, userData) {
            cur.listUsers.find((item) => item.userID === userData.userID).body = userData.pointCount;
        },

        setAllDataReady(cur, newValue) {
            cur.allDataReady = newValue;
        },

        setTest(cur, newValue) {
            cur.test = newValue;
        },

        setCountyList(cur, newValue) {
            cur.CountyList = newValue;
        },

        setCyklo(cur, newValue) {
            cur.cyklo = newValue;
        },

        setNoCyklo(cur, newValue) {
            cur.noCyklo = newValue;
        },

        setPublic(cur, newValue) {
            cur.public = newValue;
        },

        setNoPublic(cur, newValue) {
            cur.noPublic = newValue;
        },

        setLocation(cur, newValue) {
            cur.location = newValue;
        },

        setNoLocation(cur, newValue) {
            cur.noLocation = newValue;
        },

        setShowActive(cur, newValue) {
            cur.active = newValue;
        },

        setShowNoActive(cur, newValue) {
            cur.noActive = newValue;
        },

        setShowPointType(cur, pointType) {
            console.log("vybrane body");
            cur.showPointType[pointType] = !cur.showPointType[pointType];
            cur.showPoints = (cur.showPointType.sport ? '1' : '') + (cur.showPointType.cult ? '2' : '') + (cur.showPointType.eco ? '3' : '');
        },

        setSearchInPoint(cur, newValue) {
            cur.searchInPoint = newValue;
        },

        clearSearchInPoint(cur) {
            cur.searchInPoint = '';
        },

        setMarkOnMapID(cur, newValue) {
            cur.showMarkOnMapID = newValue;
        },

        setReady(cur, task) { // TODO Upravit pro nastaveni prave nactene db. Seznam potrebnych db je v poli dataReady
            cur.dataReady[task] = true;
            // let result = true;
            // for (let key in cur.dataReady) {
            //     console.log("toto je klic " + key);
            //     result &= cur.dataReady[key];
            // }
            // cur.allDataReady = !!result;
        },

        setNewMsg(cur, newValue) {
            console.log("Zprava: " + newValue);
            cur.listMsg.push({msg: newValue.msg, msgType: newValue.msgType});
        },

        removeMsg(cur) {
            cur.listMsg.shift();
        },

        setRegions(cur, newValue) {
            cur.regions = newValue;
        },

        setPsc(cur, newValue) {
            cur.psc = newValue;
        },

        setAllPoints(cur, newValue) {
            cur.allPoints = newValue;
        },

        setCurUserPoint(cur, newValue) {
            cur.curUserPoint = newValue;
        },

        setSortOn(cur, newValue) {
            cur.sortOn = newValue;
        },

        reverseSortOrder(cur) {
            cur.sortOrder = cur.sortOrder === 1 ? -1 : 1;
        },

        setMenuSelection(cur, newValue) {
            console.log("provadim zmenu hodnoty menu na " + newValue);
            cur.curMenu = newValue;
            console.log("Info po zmene " + cur.curMenu);

        },

        setUserReady(cur, newValue) {
            cur.userReady = newValue;
        },

        setCatReady(cur, newValue) {
            cur.catReady = newValue;
        },

        setPointsReady(cur, newValue) {
            cur.pointsReady = newValue;
        },

        setError(current, newValue) {
            current.hasError = newValue;
        },

        setChangeToCommit(current, newValue) {
            current.changeToCommit = newValue;
        },

        setUserSearchData(current, newValue) {
            current.user_serachData = newValue;
        },

        setLoged(current, newValue) {
            current.logedIn = newValue;
            sessionStorage.loged = newValue;
        },

        showMod(current, newValue) {
            current.showModal = newValue.show;
            current.modalTitle = newValue.title;
            current.modalMsg = newValue.msg;
            current.modalFoot = newValue.foot;
        },

        setUser(current, data) {
            current.curID = data.userID;
            current.curUser = data.nick;
            current.userToken = data.userToken;
            sessionStorage.token = data.userToken;
            sessionStorage.userid = data.userID;
            sessionStorage.nick = data.nick;
        },
        clearUser(current) {
            current.curID = "";
            current.curUser = "";
            current.userToken = "";
            sessionStorage.clear();
            for (let key in current.dataReady) {
                current.dataReady[key] = false;
            }
        },

        setTimerUpdated(cur) {
            cur.updateTimer = false;
        },

        setListUsers(current, data) {
            data.forEach((item) => item.dateTS = (new Date(item.datum)).valueOf());

            current.listUsers = data;
            current.user_selectedID = data[0].userID;
        },

        setListPoints(current, data) {
            console.log("Nastavuju list pointy");
            data.forEach((item) => item.Date = (new Date(item.Time.split(" ", 1) + " 00:00:00")).valueOf());
            current.listPoints = data;
            console.log("po nacteni")
        },

        setListCategory(current, data) {
            current.listCategory = data;
        },

        setListUsersOnPoint(current, data) {
            current.listUsersOnPoint = data;
        },

        setUserDataReady(cur, newValue) {
            cur.userDataReady = newValue;
        },

        setUserSelected(current, userID) {
            current.user_selectedID = userID;
        },

        updateUserData(current, newValue) {      //TODO zde pridat obsluhu na aktualizaci dat uzivatele. => odeslani na server
            let id = current.user_selectedID;

            let user = current.listUsers.slice().find(function (cur) {
                return cur.userID === id;
            });

            user.mobil = newValue;

            console.log(JSON.stringify(current.listUsers.find(function (cur) {
                return cur.userID === id;
            })));

            let par = {
                task: 'updateuser',          // Načtení všech uživaelů
                userID: current.curID,
                userToken: current.userToken,
                newValue: JSON.stringify(current.listUsers.find(function (cur) {
                    return cur.userID === id;
                })),
            };
            //           console.log("Parametry: " + par);
            updateUserOnServer(current, current.getters.getUrl, par);
        },


    },

    getters: {
        getLastWeekPointCount(state){
            const d = new Date();
            d.setDate(d.getDate() - 7);
            const today = new Date( d.toDateString()).valueOf(); //.valueOf();
            console.log(`Dnesni datum ${today}`);
          return state.listPoints.filter((item) => item.Date >= today & item.IsValid === '1' ).reduce((count) => count += 1, 0);
        },

        getTodayPointCount(state){
            const today = new Date( new Date().toDateString()).valueOf(); //.valueOf();
            console.log(`Dnesni datum ${today}`);
          return state.listPoints.filter((item) => item.Date === today & item.IsValid === '1' ).reduce((count) => count += 1, 0);
        },

        getUrl(state) {
            return state.test ? baseUrlTest : baseUrlLife;
        },

        getCurUserPoint(state) {
            return state.listPoints.find(item => item.ID === state.curUserPoint);
        },

        getAllDataReady(state) {
            return state.allDataReady;
        },

        getMarkers(state) {
//            state.whichToShow(state.showPoint);

            function getRegion(regID) {
                let regions = state.regions;
                let curReg = regions.find((reg) => {
                    return reg.regionID === regID;
                });

                return curReg ? curReg.region : "Region nepřiřazen";

            }

            const search = state.searchInPoint.length > 0;
            const whatSearch = state.searchInPoint.toLowerCase();
            return state.allPoints.filter(point =>
                (search ? (point.Name.toLowerCase().includes(whatSearch) || point.PointCode.includes(whatSearch)) : true) &&
                state.showPoints.includes(((parseInt(point.PointType)) & 15).toString()) &&
                (point.IsValid === (state.active ? '1' : '') || point.IsValid === (state.noActive ? '0' : '')) &&
                ((state.location ? !(point.PointSpec & POINT_SPEC.NO_LOCATION) : false) || (state.noLocation ? !!(point.PointSpec & POINT_SPEC.NO_LOCATION) : false)) &&
                ((state.public ? !(point.PointSpec & POINT_SPEC.NO_PUBLIC) : false) || (state.noPublic ? !!(point.PointSpec & POINT_SPEC.NO_PUBLIC) : false)) &&
                ((state.cyklo ? !!(point.PointType & SUB_POINT.CYKLO) : false) || (state.noCyklo ? !(point.PointType & SUB_POINT.CYKLO) : false))
            ).map((point) => ({
                    id: point.ID,
                    pointCode: point.PointCode,
                    position: {lat: parseFloat(point.Lat), lng: parseFloat(point.Lng)},
                    type: point.PointType,
                    name: point.Name,
                    address: point.Address,
                    value: point.Value,
                    valid: point.IsValid,
                    region: getRegion(point.InRegion),
                })
            );
        },

    },

    actions: {

        setDbReady(con, dbName){
            con.commit('setReady', dbName);
            let result = true;
            for (let key in con.state.dataReady) {
                console.log("toto je klic " + key);
                result &= con.state.dataReady[key];
            }
            con.commit('setAllDataReady',!!result);
            // if(con.state.allDataReady){
            //     console.log('JSEM UVNITR*****************************************');
            //     con.dispatch('prepareZIP');
            // }
        },

        prepareZIP(con){ //TODO potreba upravit na načítání dat tak, aby se naplnil přehled okresů po načtení všech dat
            console.log("Jsem v prepare ZIPU");
          let result = con.state.listUsers.map((user) => {
              // console.log(user.psc);
              let result = con.state.psc.find((item)=> item.psc === user.psc.replace(/\s+/g, ''));
              if (result){
                  return result.okres
              }else{
                  return "N/A"}
          })
            console.log(result);
          let res = [...new Set(result)];
          console.log(res);
            con.commit('setCountyList', res.sort());

        },

        updatePoint(con, newValue) {
            console.log("JSEM TU*************************************");
            let point = [...con.state.allPoints].find((cur) => cur.ID === newValue.ID);
            const originalValue = JSON.parse(JSON.stringify(point));

            Object.keys(point).forEach((key) => point[key] = newValue[key]);

            let tmpPoint = JSON.parse(JSON.stringify(point));
            let par = {
                task: 'updatePoint',          // Načtení všech uživaelů
                userID: con.state.curID,
                userToken: con.state.userToken,
                newValue: JSON.stringify(tmpPoint),
            };

            gPost(con.getters.getUrl, par, (resp) => {
                let resultError = resp.data.ERROR;
                if (resultError) {
                    con.commit('setNewMsg', {msg: resp.msg, msgType: 'alert'});
                    con.commit('setNewMsg', {msg: 'Změny nebyly uloženy do databáze', msgType: 'alert'});
                    Object.keys(point).forEach((key) => point[key] = originalValue[key]);
                } else {
                    con.commit('setNewMsg', {msg: resp.msg});
                }
            }, (resp) => {
                con.commit('setNewMsg', {msg: resp.msg, msgType: 'alert'});
                con.commit('setNewMsg', {msg: 'Změny nebyly uloženy do databáze', msgType: 'alert'});
                Object.keys(point).forEach((key) => point[key] = originalValue[key]);
            })

        },

        updateUData(context, newValue) {      //TODO zde pridat obsluhu na aktualizaci dat uzivatele. => odeslani na server
            let id = context.state.user_selectedID;

            let user = context.state.listUsers.slice().find(function (cur) {
                return cur.userID === id;
            });

            user.mobil = newValue.mobil;
            user.email = newValue.email;
            user.prezdivka = newValue.nick;
            let name = newValue.fullName.split(" ");
            user.jmeno = name[0];
            user.prijmeni = name[1];

            console.log(JSON.stringify(context.state.listUsers.find(function (cur) {
                return cur.userID === id;
            })));

            let par = {
                task: 'updateuser',          // Načtení všech uživaelů
                userID: context.state.curID,
                userToken: context.state.userToken,
                newValue: JSON.stringify(context.state.listUsers.find(function (cur) {
                    return cur.userID === id;
                })),
            };
            //           console.log("Parametry: " + par);
            console.log("Je to spravne URL? " + context.getters.getUrl);
            updateUserOnServer(context, context.getters.getUrl, par);
        },

        updateUserPointData(context, point){
            console.log("sssssssssssssssssssssssssss - Update User {pint na serveru"); //TODO Pridat aktualizaci bodu na serveru
            let par = {
                task: 'updateUserPoint',          // Načtení všech uživaelů
                userID: context.state.curID,
                userToken: context.state.userToken,
                newValue: JSON.stringify(point),
            };

            gPost(context.getters.getUrl, par, (resp) => {
                let resultError = resp.data.ERROR;
                if (resultError) {
                    context.commit('setNewMsg', {msg: resp.msg, msgType: 'alert'});
                } else {
                    context.commit('setNewMsg', {msg: resp.msg});
                    let user = context.state.listUsers.find((curUser) => {
                        return curUser.userID === point.ID_User;
                    });
                    user.body = parseInt(user.body) + parseInt(point.ValuePoint) * (point.IsValid === '0' ? -1 : 1);
                }
            }, (resp) => {
                context.commit('setNewMsg', {msg: resp.msg, msgType: 'alert'});
            })

        },

        setPointValidity(context, point) {
                       console.log("sssssssssssssssssssssssssss"); //TODO Pridat aktualizaci bodu na serveru
            let selPoint = context.state.listPoints.find((curPoint) => {
                return curPoint.ID === point.ID;
            });

            let tmpPoint = JSON.parse(JSON.stringify(selPoint));
            tmpPoint.IsValid = point.IsValid === '1' ? '0' : '1';
            let par = {
                task: 'updateUserPoint',          // Načtení všech uživaelů
                userID: context.state.curID,
                userToken: context.state.userToken,
                newValue: JSON.stringify(tmpPoint),
            };

            gPost(context.getters.getUrl, par, (resp) => {
                let resultError = resp.data.ERROR;
                if (resultError) {
                    context.commit('setNewMsg', {msg: resp.msg, msgType: 'alert'});
                } else {
                    context.commit('setNewMsg', {msg: resp.msg});
                    selPoint.IsValid = tmpPoint.IsValid;
                    let user = context.state.listUsers.find((curUser) => {
                        return curUser.userID === point.ID_User;
                    });
                    user.body = parseInt(user.body) + parseInt(point.ValuePoint) * (point.IsValid === '0' ? -1 : 1);
                }
            }, (resp) => {
                context.commit('setNewMsg', {msg: resp.msg, msgType: 'alert'});
            })

            /*            selPoint.IsValid = point.IsValid === '1' ? '0' : '1';
                        let user = context.state.listUsers.find((curUser) => {
                            return curUser.userID === point.ID_User;
                        });
                        user.body = parseInt(user.body) + parseInt(point.ValuePoint) * (point.IsValid === '0' ? -1 : 1);

                        let par = {
                            task: 'updateUserPoint',          // Načtení všech uživaelů
                            userID: context.state.curID,
                            userToken: context.state.userToken,
                            newValue: JSON.stringify(selPoint),
                        };
                        console.log("Parametry: " + par);
                        // console.log(JSON.stringify(this));
                        updateUserOnServer(context, par);*/
        },


        addPointToUser(context, pointCode) {
            const newValue = {
                userID: context.state.user_selectedID,
                pointCode: pointCode
            }

            let par = {
                task: 'addUserPoint',          // Načtení všech uživaelů
                userID: context.state.curID,
                userToken: context.state.userToken,
                newValue: JSON.stringify(newValue),
            };

            gPost(context.getters.getUrl, par, (resp) => {
                let resultError = resp.data.ERROR;
                if (resultError) {
                    context.commit('setNewMsg', {msg: resp.msg, msgType: 'alert'});
                } else {
                    context.commit('setNewMsg', {msg: resp.msg});
                    //TODO tady to upravit pro načteni z jenoho místa a lépe
                    /*                    par.task = 'ListUsers';
                                        gPost(context.getters.getUrl, par, function (response) {
                                                if (response.ERROR === "true") {
                                                    context.commit('setNewMsg', {msg: response.msg});
                                                } else {
                                                    context.commit('setListUsers',response.data);
                                                    context.commit('setNewMsg', {msg: response.msg});
                                                }
                                            },
                                            function (response) {
                                                context.commit('setNewMsg', response);
                                            });*/

                    par.task = 'ListPoints';
                    gPost(context.getters.getUrl, par, function (response) {
                            if (response.ERROR === "true") {
                                context.commit('setNewMsg', {msg: response.msg});
                            } else {
                                context.commit('setListPoints', response.data);
                                context.commit('setNewMsg', {msg: response.msg});
                            }
                        },
                        function (response) {
                            context.commit('setNewMsg', response);
                        });


                }
            }, (resp) => {
                context.commit('setNewMsg', {msg: resp.msg, msgType: 'alert'});
            })
        },


    },




});


function updateUserOnServer(cur, url, param) {
    console.log("Tady ma byt aktualizace na serveru");
    console.log("Je to spravne URL? " + url);

    gPost(url, param, (resp) => cur.commit('setNewMsg', resp), (resp) => cur.commit('setNewMsg', {
        msg: resp.msg,
        msgType: 'alert'
    }));
    // gPost(param, (resp) => cur.commit('setNewMsg', resp), (resp) => console.log(resp));

}

/*
function gPost(param, suc, fail) {
    axios.post(baseUrl, param, {
        validateStatus: function () {
            return true;
        }
    }).then(response => {
            let resultError = response.data.ERROR;
            if (resultError) {
                fail(response.data);
            } else {
                suc(response.data);
            }
        }
    ).catch(response => {
            response.msg = "Nelze komunikovat se serverem. Chyba spojení."
            fail(response);

        }
    )
}
*/

