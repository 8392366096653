<script>
// import { POINT_MARKER_ICON_CONFIG } from "@/constants/mapSettings";
//import GoogleInfoWindow from "@/components/maps/GoogleInfoWindow";

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    infowindow: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    showPoint: {
      type: Number,
      required: false,
      default: 0
    }

  },

  data() {
    return {
      mark: null,
    }
  },
  methods: {
    iconType() {
      let result;
      switch (this.marker.type & 7) {
        case 1:
          result = './img/mapa_sport_small.png';
          break;
        case 2:
          result = './img/mapa_kultura_small.png';
          break;
        case 3:
          result = './img/mapa_ekologie_small.png';
          break;
        default:
          result = './img/mapa_sport_small.png';
          break;
      }
      return result;
    }
  },

  watch: {
    showPoint: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal === parseInt(this.marker.id)) {
          // this.map.setCenter(this.marker.position);
          //         this.map.panTo(this.marker.position);
          this.infowindow.setContent(generateInfoWindow(this.marker, this));
          this.infowindow.open(this.map, this.mark);
        }
      }
    },
    /*    // Toto řešení moc nefunguje a neudělá změnu pozice ukazatele na mapě. Musím vymyslet něco jiného
        'marker.position':function(newVal, oldVal){
          console.log("Zaznamenal jsem zmenu");
          if (newVal != oldVal){
            this.mark.position = newVal;
          }
        }
    */
  },

  mounted() {
    const {Marker} = this.google.maps;
//     console.log("Delam MAPU****************************************" + this.iconType());

    this.mark = new Marker({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      // icon: POINT_MARKER_ICON_CONFIG
      icon: {url: this.iconType(), scaledSize: new this.google.maps.Size(27, 38)}
    });

    /*    const {InfoWindow} = this.google.maps;
        const inWind = new InfoWindow;*/
    /*
    Pridani reakce na click je ok, ale nezobrazuje se infoWindow. Je to potreba opravit a uzpusobit pro nas.
     */
    this.mark.addListener('click', () => {
      // alert(this.marker.name);
      /*
            console.log("Tady ma bezet to okenko");
            this.infowindow.setContent(`<div>Ahoj: ${this.marker.name} </div>`);
            this.infowindow.open(this.map, this.mark);
      */
      this.$store.commit('setMarkOnMapID', parseInt(this.marker.id));

      /*
            inWind.setContent("<div>Ahoj: ${this.marker.name} </div>");
            inWind.open(this.map, this.mark);
      */

    });
    /*    console.log(`Ktery bod resim ${this.marker.id} a je to shoda ${this.showPoint}`);
        if (this.$store.state.showMarkOnMapID === parseInt(this.marker.id)) {
          //     alert("Je to shoda");
          this.infowindow.setContent(`<div>Ahoj: ${this.marker.name} </div>`);
          this.infowindow.open(this.map, this.mark);
        }*/

// console.log("Tady davam ten mark");
//  this.$emit('addMarker', {mark: mark});

  },

  beforeDestroy() {
    this.mark.setMap(null);
  },

  // eslint-disable-next-line vue/require-render-return
  render() {
  }
};

function generateInfoWindow(mark, thisApp) {
  return `<div class="infoBlock ${(mark.valid === '0')? 'inactive':'active'}">
<div class=""><div class="type ${thisApp.getType(mark.type)}">${thisApp.getType(mark.type)} </div><div class="region">${mark.region}</div> <div class="value">${mark.value}</div></div>
<hr class="clear">
<div class="title clear"> ${mark.name} </div>
<div class="address">${mark.address}</div>

</div>`;
}

</script>

<style>
.infoBlock {
  min-width: 300px;
  padding: 5px;
  border-radius: 5px;

}

.infoBlock .type {

  color: white;
  padding: 2px 5px;
  margin-right: 5px;
  font-size: 0.8em;
  float: left;
  border-radius: 4px;

}

.infoBlock .region {
  float: left;
}

.infoBlock .value {
  text-align: right;
  font-weight: bold;
  font-size: 1.2em;
  float: right;
}



</style>


